/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import type { CitrusCarouselResponse } from '../interface';
import { usePageType } from '@/features/category/api/getPageType';
import { type PageContextType } from '@/next-types';
import { getCitrusShelfCarouselData as internalGetCitrusShelfCarouselData } from '../utils/apiUtils.server';
import { useFeatureFlag } from '@/features/globalConfig';
import { getDecodedCookies } from '@/utils/cookie';
import { getCitrusCatalogId } from '../utils/getCitrusCatalogId';
import environmentConfig from '@/config/environment';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';
import { appendVehicleIdFilters } from '../utils/appendVehicleIdFilters';

const URL = `/api/citrus/shelf-spa`;
const PROXY_URL = '/v1/ads/generate';

type Options = {
  enabled?: boolean;
  category: string;
  vehicleId: string;
};

const getCitrusShelfCarouselDataWithProxy = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusShelfCarouselKey>>) => {
  const [, , , { category, vehicleId }] = queryKey;
  const cookies = getDecodedCookies(['eCookieId', 'rewardsId', 'forceCitrusProd']);
  const rewardsId = cookies.get('rewardsId');
  const eCookieId = cookies.get('eCookieId');
  const forceCitrusProd = !!cookies.get('forceCitrusProd');
  const { baseURL, axiosConfig } = getCitrusProxyConfiguration();

  const response = await getAxiosForClient().post<CitrusCarouselResponse>(
    `${baseURL}${PROXY_URL}`,
    {
      customerId: rewardsId ?? null,
      sessionId: rewardsId || eCookieId,
      placement: 'category-shelf-product',
      catalogId: getCitrusCatalogId(forceCitrusProd),
      maxNumberOfAds: 3,
      productFilters: appendVehicleIdFilters([`category:${category}`], vehicleId),
      options: {
        filterMode: 'AndOr',
      },
    },
    axiosConfig
  );
  return selector(response.data);
};

const getCitrusShelfCarouselData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusShelfCarouselKey>>) => {
  const [, , , { category, vehicleId }] = queryKey;

  const params = {
    category,
    vehicleId,
  };

  const response = await getAxiosForClient().post<CitrusCarouselResponse>(URL, params);
  return selector(response.data);
};

const selector = (data: CitrusCarouselResponse): CitrusCarouselResponse => data;

const getCitrusShelfCarouselKey = (options: Options) =>
  ['citrus', 'shelf', 'carousel', options] as const;

export const useCitrusShelfCarouselData = (options: Omit<Options, 'vehicleId' | 'category'>) => {
  const preferredVehicle = usePreferredVehicle();
  const { data: pageTypeData } = usePageType();

  const category = pageTypeData?.catalogRepositoryName ?? '';

  const queryKey = {
    category,
    vehicleId: preferredVehicle?.catalogVehicleId ?? '0',
  };
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;

  return useQuery({
    enabled:
      !!citrusEnabled &&
      !!category &&
      pageTypeData?.pageType === 'ProductShelf' &&
      (options.enabled ?? true),
    queryKey: getCitrusShelfCarouselKey(queryKey),
    queryFn: !CITRUS_PROXY_DISABLED
      ? getCitrusShelfCarouselDataWithProxy
      : getCitrusShelfCarouselData,
    cacheTime: 0,
  });
};

export const getCitrusShelfCarouselDataFromCache = (
  { category, vehicleId }: { category: string; vehicleId: string },
  queryClient: QueryClient
) => {
  return queryClient.getQueryData<CitrusCarouselResponse>(
    getCitrusShelfCarouselKey({ category, vehicleId })
  );
};

export const prefetchCitrusShelfCarouselData = (
  queryClient: QueryClient,
  context: PageContextType,
  {
    category,
    vehicleId,
  }: {
    category: string;
    vehicleId: string;
  },
  eCookieId: string
) => {
  return queryClient.prefetchQuery({
    queryKey: getCitrusShelfCarouselKey({ category, vehicleId }),
    queryFn: () =>
      internalGetCitrusShelfCarouselData({
        req: context.req,
        res: context.res,
        category,
        vehicleId,
        eCookieId,
      }),
  });
};
