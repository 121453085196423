/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { View, Text } from '@az/starc-ui';
import cx from 'classnames';
import styles from './styles.module.scss';
import { whiteInfoIcon } from '@/constants/images/infoIcon';
import Image from '@/components/NextImage';
import { autoZoneRewardsIcon } from '@/constants/images/autoZoneRewardsIcon';
import InfoToolTip from '@/components/AZCustomComponent/InfoToolTip';

type RewardsBadgeProps = {
  amount: number;
  className?: string;
};

export const RewardsBadge: React.FC<RewardsBadgeProps> = ({ amount, className }) => {
  return (
    <View direction="row" align="center">
      <View
        direction="row"
        align="center"
        justify={'center'}
        className={cx(styles.rewardsBadge, className)}
        attributes={{ 'data-testid': 'rewards-badge-container' }}
      >
        <Image
          src={autoZoneRewardsIcon.src}
          alt={autoZoneRewardsIcon.alt}
          width={27.51}
          height={16}
          data-testid="rewards-badge-icon"
        />
        <Text
          as="span"
          className={styles.rewardText}
          attributes={{ 'data-testid': 'rewards-badge-text' }}
        >
          {`$${amount} Bonus Reward`}
        </Text>
        <InfoToolTip
          classes={{
            icon: styles.infoIcon,
            container: styles.infoToolTipContainer,
          }}
          iconSrc={whiteInfoIcon}
          header={''}
          body={''}
          data-testid="rewards-badge-info-tooltip"
        />
      </View>
    </View>
  );
};
