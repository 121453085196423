/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLabels } from '@/hooks/useLabels';
import { NavBar } from '@/components/NavBar';
import type { SkuDetails } from '../../../interface';
import FulfillmentOptionsV2 from '@/components/FulfillmentOptionsV2';
import OldFulfillmentOptionsV2 from '@/components/OldFulfillmentOptionsV2';
import { cartConstants } from '@/constants/cart';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import { getFulfillmentOptionsByGroup } from '@/utils/itemHelpers/fulfillmentHelpers/getFulfillmentOptionsByGroup';
import { convertFulfillmentTypeToOrderType } from '@/utils/itemHelpers/lineItemHelpers/convertFulfillmentTypeToOrderType';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import type { ReduxState } from '@/types';
import styles from './styles.module.scss';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { useFeatureFlag } from '@/features/globalConfig';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { FulfillmentOptions as FulfillmentOptionsMX } from '@/components/FulfillmentOptions';
import { useProductList } from '@/features/shelf/context/useProductList';
import type {
  LineItemFromState,
  OrderPriceInfo,
  ProductInfoFromState,
} from '@/types/reduxStore/orders';
import Router from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { localStorage } from '@/utils/localStorage';
import { useDealDrawerFulfillmentSelection } from '../../../api/getDealDrawerFulfillmentSelection';
import { getErrorCode, getErrorCodeDescription, getErrorMessage } from '@/utils/errorsHandling';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import { Button } from '@az/starc-ui';
import { trackDealBundleDeliveryMethod } from '@/utils/analytics/track/trackDealBundleDeliveryMethod';
import { useExecuteOnce } from '@/hooks/useExecuteOnce';
import { type ShippingType } from '@/features/fulfillment';

const labelMap = {
  label_MyAccountAddService_form_Confirm: 'label_MyAccountAddService_form_Confirm',
  label_deal_drawer_fulfillment_selection_ChooseADeliveryOption:
    'label_deal_drawer_fulfillment_selection_ChooseADeliveryOption',
  label_deal_drawer_fulfillment_selection_DeliveryMethods:
    'label_deal_drawer_fulfillment_selection_DeliveryMethods',
  label_deal_drawer_unfortunately_deal_no_longer_available:
    'label_deal_drawer_unfortunately_deal_no_longer_available',
} as const;

type Props = {
  parentSkuDetails: SkuDetails;
  skuFulfillmentTypeId: number | string | null | undefined;
  setFulfillmentId: (fulfillmentID: number | undefined) => void;
  canAutoSelectFulfillment: boolean;
  onConfirmDealSelection: (groupTypeId: string | null) => void;
  onClose: () => void;
  handleInstockAnalytics?: () => void;
  handleErrorAnalytics: (a: string, b: string) => void;
  isDealDrawer?: boolean;
  preferredVehicleId: string | undefined;
  dealId: string;
  dealName: string;
};

export const FulfillmentSelection = ({
  parentSkuDetails,
  skuFulfillmentTypeId,
  canAutoSelectFulfillment,
  onConfirmDealSelection,
  onClose,
  handleInstockAnalytics,
  handleErrorAnalytics,
  isDealDrawer,
  preferredVehicleId,
  dealId,
  setFulfillmentId,
  dealName,
}: Props) => {
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeNumber = storeDetailsData?.storeNumber;

  const { id: skuId } = parentSkuDetails;

  const fulfillmentSelectionQuery = useDealDrawerFulfillmentSelection({
    storeNumber,
    skuId,
    dealId,
    selectedVehicle: preferredVehicleId,
  });

  const { openSisterStoreFlyout, setUsingAutoClose } = useContext(SisterStoreContext);

  useEffect(() => {
    setUsingAutoClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { fullFulfillmentOptions } = fulfillmentSelectionQuery.data ?? {};
    const option = fullFulfillmentOptions?.[skuFulfillmentTypeId ?? ''];

    if (
      fullFulfillmentOptions &&
      Object.keys(fullFulfillmentOptions).length === 1 &&
      option?.available &&
      canAutoSelectFulfillment
    ) {
      onConfirmDealSelection(`${option.groupTypeId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fulfillmentSelectionQuery.data?.fullFulfillmentOptions,
    skuFulfillmentTypeId,
    canAutoSelectFulfillment,
  ]);

  const labels = useLabels(labelMap);
  const [shippingType, setShippingType] = useState<ShippingType>(
    convertFulfillmentTypeToOrderType(Number(skuFulfillmentTypeId))
  );
  const cartData = useSelector((state: ReduxState) => state.orders.details.orderItems);
  const [isFulfillmentOptionSelected, setIsFulfillmentOptionSelected] = useState(false);
  const [groupId, setGroupId] = useState<string | null>(String(skuFulfillmentTypeId));
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const locale = useLocale();
  const isUS = locale === countryCodes.us;
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const [showSisterModal, setShowSisterModal] = useState(false);
  const [storeAvailable, setStoreAvailable] = useState(false);
  const orderCartByTime = useFeatureFlag('CART_TIMESTAMP_ORDER') === 'true';

  const fulfillmentOptionsByGroup = getFulfillmentOptionsByGroup(
    // @ts-expect-error fullFulfillmentOptions has more properties that are expected by type of the argument, it requires a refactor to correctly match type
    fulfillmentSelectionQuery.data?.fullFulfillmentOptions
      ? Object.values(fulfillmentSelectionQuery.data.fullFulfillmentOptions)
      : undefined
  );

  const commerceItemsList: Array<
    LineItemFromState & {
      shipToHome: boolean;
      storePickUp: boolean;
      sendListToStore: boolean | undefined;
      orderType: string;
      orderPriceInfo: OrderPriceInfo;
      productInfo: ProductInfoFromState;
    }
  > = [];

  if (cartData && Object.keys(cartData).length > 0) {
    cartData.forEach((orderItem) => {
      const { orderType, orderPriceInfo } = orderItem;
      const shipToHome = orderType === cartConstants.ONLINEORDER;
      const storePickUp = orderType === cartConstants.STOREORDER && isBopusEnabled;
      const sendListToStore =
        storeData && orderType === cartConstants.STOREORDER && !isBopusEnabled;
      orderItem.shipmentInfoList.filter((item) =>
        item.lineItemList.map((cartItem) => {
          const newData = {
            ...cartItem,
            shipToHome,
            storePickUp,
            sendListToStore,
            orderType,
            orderPriceInfo,
          };
          commerceItemsList.push(newData);
          if (orderCartByTime) {
            commerceItemsList.sort((a, b) => {
              // @ts-expect-error verify if itemAddedToCartTS is coming from type
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              const d1 = new Date(a.itemAddedToCartTS);
              // @ts-expect-error verify if itemAddedToCartTS is coming from type
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              const d2 = new Date(b.itemAddedToCartTS);
              if (d1 < d2) {
                return 1;
              } else if (d1 > d2) {
                return -1;
              } else {
                return 0;
              }
            });
          }

          return commerceItemsList;
        })
      );
    });
  }

  const productDetailsQuery = useProductDetails({ enabled: false });
  const { data: shelfData } = useProductList();
  const parts = shelfData?.shelfParts;
  const skuDetailsResult = useProductSkuDetails({
    skuIds:
      productDetailsQuery.isSuccess && productDetailsQuery.data.product?.itemId
        ? [productDetailsQuery.data.product.itemId]
        : null,
  });
  const isStoreSelected = useIsStoreSelected();
  const skuDetailsData = skuDetailsResult.isSuccess ? skuDetailsResult.data[0] : null;
  const filterproduct =
    parts?.find((part) => String(part.skuNumber) === String(skuId)) ?? skuDetailsData;

  const onConfirm = () => {
    if (showSisterModal) {
      openSisterStoreFlyout({
        skuId,
        shippingType,
        quantityInCart: skuDetailsData?.quantityInCart ?? 0,
        dealId,
        productDetails: skuDetailsData ?? undefined,
      });
    } else {
      onConfirmDealSelection(groupId);
    }
  };

  useExecuteOnce({
    enabled: fulfillmentOptionsByGroup.length > 0,
    fn: () => {
      const dealsFulfillmentMethods = fulfillmentOptionsByGroup.map(
        ({ groupId, available }) => `${groupId}-${available.toString()}`
      );
      trackDealBundleDeliveryMethod(dealId, dealName, dealsFulfillmentMethods);
    },
  });

  useEffect(() => {
    const radioValue = fulfillmentOptionsByGroup.map((item) => {
      const sisterStoreAvailable = !!item.groupFulfillmentOptions.find(
        (option) => option.sisterStoreAvailable
      );

      return (
        item.groupId ===
          (shippingType === cartConstants.STOREORDER
            ? FULFILLMENT_METHODS.STOREORDER
            : FULFILLMENT_METHODS.ONLINEORDER) &&
        (item.available || sisterStoreAvailable)
      );
    });

    const sisterAvailable = fulfillmentOptionsByGroup.find((item) => {
      const sisterStoreAvailable = !!item.groupFulfillmentOptions.find(
        (option) =>
          option.sisterStoreAvailable && option.fulfillmentTypeId === FULFILLMENT_METHODS.STOREORDER
      );

      return sisterStoreAvailable;
    });

    if (sisterAvailable && shippingType === cartConstants.STOREORDER) {
      setStoreAvailable(true);
    } else {
      setStoreAvailable(false);
    }

    const isValueSelected = radioValue.every((currentVal) => !currentVal);
    setIsFulfillmentOptionSelected(isValueSelected);
    if (storeAvailable && shippingType === cartConstants.STOREORDER) {
      setShowSisterModal(true);
    } else {
      setShowSisterModal(false);
    }
  }, [shippingType, groupId, storeAvailable, fulfillmentOptionsByGroup]);

  const handleChangeShippingTypeV2 = (
    shippingType: ShippingType,
    newGroupId: typeof FULFILLMENT_METHODS.STOREORDER,
    maxAvailableQty: number,
    sisterStoreAvailable: boolean,
    fulFillmentId?: number
  ) => {
    setFulfillmentId(fulFillmentId);
    setShippingType(shippingType);
    setGroupId(String(newGroupId));
  };

  if (fulfillmentSelectionQuery.isError) {
    handleErrorAnalytics(
      getErrorMessage(fulfillmentSelectionQuery.error) ?? '',
      getErrorCode(fulfillmentSelectionQuery.error) ?? ''
    );

    return (
      <>
        <NavBar showCloseButton handleClose={onClose} />
        <div className={styles.bundleDetailsBody}>
          <div className={styles.errorContainer}>
            <NotificationBlock
              type="error"
              message={getErrorCodeDescription(fulfillmentSelectionQuery.error)}
            />
          </div>
        </div>
      </>
    );
  } else if (fulfillmentSelectionQuery.isLoading && fulfillmentSelectionQuery.isFetching) {
    return (
      <div data-testid="fulfillment-loading-indicator" className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  const hideConfirmButton = fulfillmentOptionsByGroup.every((f) => {
    return !f.available;
  });
  const priceInfo =
    filterproduct?.skuPricingAndAvailability?.retailPrice ||
    skuDetailsData?.skuPricingAndAvailability.retailPrice ||
    commerceItemsList[0]?.lineItemPriceInfo?.retailPrice ||
    0;
  const [dollars, cents = '00'] = priceInfo.toString().split('.');

  return fulfillmentSelectionQuery.data?.fullFulfillmentOptions &&
    fulfillmentOptionsByGroup.length > 0 ? (
    <div className={styles.fulfillmentSelectionDetails}>
      <NavBar showCloseButton handleClose={onClose} />
      <div className={styles.fulfillmentSelectionScroller}>
        <h2 className={styles.fulfillmentSelectionTitle}>
          {labels.label_deal_drawer_fulfillment_selection_DeliveryMethods}
        </h2>
        {isUS ? (
          <div className={styles.fulfillmentSelectionDescription}>
            {labels.label_deal_drawer_fulfillment_selection_ChooseADeliveryOption}
          </div>
        ) : null}

        <div className={styles.separator} />
        {hideConfirmButton ? (
          <div className={styles.noDealMessage}>
            {labels.label_deal_drawer_unfortunately_deal_no_longer_available}
          </div>
        ) : null}
        <div>
          {locale !== countryCodes.mx ? (
            isBopusNewMsgApplicable ? (
              <FulfillmentOptionsV2
                availabilityInfoVO={filterproduct?.availabilityInfoVO}
                fulfillmentOptionsGroupList={fulfillmentOptionsByGroup}
                itemPresentInCart={skuDetailsData?.itemPresentInCart}
                changeShippingType={handleChangeShippingTypeV2}
                shippingType={shippingType}
                productName={parentSkuDetails.description}
                skuId={skuId}
                isDealDrawer={isDealDrawer}
                dealId={dealId}
                handleInstockAnalytics={handleInstockAnalytics}
                quantityInCart={skuDetailsData?.quantityInCart ?? 0}
                itemData={commerceItemsList[0]}
                setFulfillmentId={setFulfillmentId}
              />
            ) : (
              <OldFulfillmentOptionsV2
                fulfillmentOptionsGroupList={fulfillmentOptionsByGroup}
                changeShippingType={handleChangeShippingTypeV2}
                shippingType={shippingType}
                productName={parentSkuDetails.description}
                skuId={skuId}
                handleInstockAnalytics={handleInstockAnalytics}
              />
            )
          ) : isBopusMexicoStoreEnabled ? (
            Router.pathname === routePaths.cart ? (
              <FulfillmentOptionsMX
                shippingType={shippingType}
                productName={parentSkuDetails.description}
                storeSelected={!!isStoreSelected}
                itemData={commerceItemsList[0]}
                fulfillmentOptionsGroupList={
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  JSON.parse(localStorage.getItem('fulfillmentOptionsGroupList')!) || []
                }
                isCartPage={true}
                skuId={commerceItemsList[0]?.productInfo?.skuId}
                quantityInCart={skuDetailsData?.quantityInCart ?? 0}
                dollars={dollars}
                cents={cents}
                inline={false}
                dealId={dealId}
              />
            ) : (
              <FulfillmentOptionsMX
                shippingType={shippingType}
                productName={parentSkuDetails.description}
                storeSelected={filterproduct?.storeSelected}
                itemData={filterproduct}
                fulfillmentOptionsGroupList={fulfillmentOptionsByGroup}
                skuId={skuId}
                quantityInCart={skuDetailsData?.quantityInCart ?? 0}
                dollars={dollars}
                cents={cents}
                inline={true}
                availabilityInfoVO={skuDetailsData?.availabilityInfoVO}
                // @ts-expect-error this is a hack to mark MX products as out of stock when PDP says otherwise
                skuPricingAndAvailability={
                  hideConfirmButton
                    ? {
                        ...(filterproduct?.skuPricingAndAvailability ||
                          skuDetailsData?.skuPricingAndAvailability),
                        storePickupAvailable: false,
                        storePickupStockLabel: 'No disponible en',
                        skuAvailabilityInfo: {
                          ...(filterproduct?.skuPricingAndAvailability?.skuAvailabilityInfo ||
                            skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo),
                        },
                      }
                    : filterproduct?.skuPricingAndAvailability?.skuAvailabilityInfo ||
                      skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo
                }
                dealId={dealId}
              />
            )
          ) : null}
        </div>
      </div>
      {!hideConfirmButton ? (
        <div className={styles.fulfillmentSelectionFooter}>
          <Button onClick={onConfirm} disabled={isFulfillmentOptionSelected}>
            {labels.label_MyAccountAddService_form_Confirm}
          </Button>
        </div>
      ) : null}
    </div>
  ) : null;
};
