/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMediaQuery } from '@/hooks/useMediaQuery';
import azCommonStyles from '../../../../theme/globals.module.scss';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useSelector } from 'react-redux';

const labelMap = {
  lblNotes: 'label_CategoryPage_body_Notes',
  lblReadMore: 'hyperlink_CategoryPage_body_ReadMore',
};

type TechNotesProps = {
  techNotes: string;
  onToggleClick: () => void;
  isSuperSku: boolean;
};

const PdpTechNotes = (props: TechNotesProps) => {
  const { techNotes, onToggleClick } = props;
  const labels = useLabels(labelMap);
  // @ts-expect-error fix type
  const deviceType: DeviceTypes = useSelector<ReduxState>((state) => state.appData.deviceType);
  const isBot = deviceType === 'bot';
  let notes = techNotes;
  let techNotesMoreOrLess = null;
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const truncateSize = isDesktop ? 130 : 60;

  if (techNotes.length > truncateSize && !isBot) {
    notes = techNotes.substring(0, truncateSize).concat('...');
    techNotesMoreOrLess = (
      <div>
        <button
          className={cx(azCommonStyles['az-body-2-regular'], styles.readmore)}
          onClick={onToggleClick}
        >
          <p>{labels.lblReadMore}</p>
        </button>
      </div>
    );
  }

  return (
    <>
      <div
        className={cx(azCommonStyles['az-body-2-regular'], styles.note, {
          [styles.superSku]: props.isSuperSku,
        })}
        data-testid="abbreviated-notes"
      >
        <span className={azCommonStyles['az-body-2-heavy']}>{labels.lblNotes}: </span>
        <span>{notes}</span>
      </div>
      {techNotesMoreOrLess}
    </>
  );
};

export default PdpTechNotes;
