/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { Store } from '@/types';
import { eventTypes } from '@/constants/event';
import { routePaths } from '@/constants/routePaths';
import { selectOrderDetailsFromState } from '@/constants/selectors';
import type {
  PageSpecificAnalyticsDataType,
  QOHErrorAnalytics,
  QohErrorAnalyticsData,
} from '@/types/analytics';
import {
  CMSModularHandler,
  hasCMSPremiumTable,
  SEOProductSchema,
  ProductDetailMetadata,
} from '@/features/pdp';
import { useContentStackDefaultPDPContent } from '@/features/pdp/api/getContentStackDefaultProductContent';
import { useContentStackPremiumPDPContent } from '@/features/pdp/api/getContentStackPremiumProductContent';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageViewCall } from '@/utils/analytics/usePageViewCall';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { analyticsPageType } from '@/constants/analytics/analyticsPageType';
import { searchAnalyticsKeys } from '@/constants/analytics/pageSpecificRemoveAnalyticsKeys';
import { analyticsDynamicPageName } from '@/utils/analytics/legacyUtils/analyticsDynamicPageName';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { getSeoPageType } from '@/utils/analytics/legacyUtils/getSeoPageType';
import { pageTypes } from '@/utils/analytics/legacyUtils/pageTypes';
import { getAnalyticsRelativeComplementOf } from '@/utils/analytics/getAnalyticsRelativeComplementOf';
import { getProductStrikethroughAnalyticValue } from './analytics/getProductStrikethroughAnalyticValue';
import { type AddCertonaItemObjectType } from '@/features/certona';
import { useAddToCartWithMiniCart } from '@/features/certona/hooks/useAddToCartWithMiniCart';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { certonaPageType } from '@/constants/certonaPageType';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { PreFooter } from '@/features/prefooter';
import { useProductSkuDeals } from '@/features/product/api/getProductDeals';
import { useSylvaniaVideos } from '@/features/product/api/getSylvaniaVideos';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useScrollIntoViewDOM } from '@/hooks/useScrollIntoView';
import { useRouter } from 'next/router';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import type { ReduxState } from '@/types';
import { convertOrderTypeToFulfillmentType } from '@/utils/itemHelpers/lineItemHelpers/convertOrderTypeToFulfillmentType';
import { getMaxAvailQtyFromOptions } from '@/utils/itemHelpers/fulfillmentHelpers/getMaxAvailQtyFromOptions';
import { sessionStorage } from '@/utils/sessionStorage';
import { getPathnameFromAsPath, stringifyUrl } from '@/utils/urlHelpers';
import { useEffect, useState, Suspense, useMemo, useRef, useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import root from 'window-or-global';
import LeftSectionSkeletonLoading from '../../components/ProductDetail/ProductAvailabilityInfo/SkeletonLoading/LeftSection';
import RightSectionSkeletonLoading from '../../components/ProductDetail/ProductAvailabilityInfo/SkeletonLoading/RightSection';
import actionTypes from '../../types/action';
import usePrevious from '../../utils/usePrevious';
import ErrorPage from '../ErrorPage';
import Hidden from '../Hidden';
import { accessoriesOptions } from './accessoriesOptions';
import ProductAvailabilityInfo from './ProductAvailabilityInfo/';
import ProductAvailabilityInfoSkeleton from './ProductAvailabilityInfo/ProductAvailabilityInfoSkeleton';
import productDetailAnalytics from './productDetailAnalytics';
import styles from './styles.module.scss';
import { DealDrawer } from '@/features/deals/components/DealDrawer/DealDrawer';
import { type FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { DynamicLinks } from '@/features/dlm/components/DynamicLinks';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { Drawer } from '../Drawer/Drawer';
import { siteSections } from '@/utils/siteSections';
import { TrapFocus } from '../Dialog/Modal/TrapFocus';
import { useAutoCloseOnOrderReady } from '@/features/sisterStoreFlyout';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { formatPartTypeId } from '@/utils/formatPartTypeId';
import { type SylvaniaVideoModel } from '@/api/types/products-types';
import { View } from '@az/starc-ui';
import { fetchPaymentOptions } from '@/actions/checkout';
import {
  getCurrencySymbol,
  getFormattedPriceMultiplied,
  getStaticFormattedPrice,
} from '@/utils/priceHelpers';
import { useHandleRedirect } from '@/features/pdp/hooks/useHandleRedirect';
import { type ShippingType } from '@/features/fulfillment';
import { useContentStackLivePreview } from '@/hooks/useContentStackLivePreview';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';

const LazyMiniCart = dynamic(
  () => import('../Cart/MiniCartModal').then((mod) => mod.MiniCartModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyMiniCartFlyoutComp = dynamic(
  () =>
    import('@/features/miniCartFlyout/components/MiniCartFlyoutComp').then(
      (mod) => mod.MiniCartFlyoutComp
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyProductCustomizableSKU = dynamic(
  () =>
    import('@/features/pdp/components/ProductCustomizableSKU').then(
      (mod) => mod.ProductCustomizableSKU
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

type AnalyticsViewPayload = {
  categoryLevel1: string;
  categoryLevel2: string;
  categoryLevel3: string;
  categoryLevel4: string;
  pageName: string;
  siteSection: string;
  pageType: string;
  pageHierarchy: string;
  seoPageType?: string;
  seoPopularSearches?: boolean;
  seoPopularSearchesClick: boolean;
  autoCorrectedKeyword: string;
  vehicleId?: string;
  fitsVehicle?: boolean;
  prodView: string;
  skuView: Array<string>;
  productFindingMethod?: string;
  pfmLocation?: string;
  productStrikeThroughPricing: string[];
  searchMethod: string;
  tvVideoName: string;
  videoAvailable: boolean;
  productRank?: string | number | null | undefined;
};

type WarrantyModal = {
  isShown: boolean;
  showModalFrom?: string;
  warrantyType?: string;
};

const { siteSectionPdp, siteSectionCatalog } = siteSections;
const trackSortFilter = () => {
  clickTrack({
    eventType: 'sortReviewsOptionsInteractionPDP',
  });
};

const trackPdpQoHFailure = (qohErrorAnalyticsData: QohErrorAnalyticsData) => {
  const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
  const data: QOHErrorAnalytics = {
    eventType: 'addToCartQohError',
    pageName,
    pageType,
    siteSection,
    originalPartTypeIdList: [qohErrorAnalyticsData.originalPartTypeId ?? ''],
    productAvailableQuantityList: [qohErrorAnalyticsData.productAvailableQuantity.toString() ?? ''],
    productBrandList: qohErrorAnalyticsData.productBrandList,
    productCorePriceList: qohErrorAnalyticsData.productCorePriceList,
    productDiscountPriceList: [''],
    productFulfillmentTypeList: [
      convertOrderTypeToFulfillmentType(qohErrorAnalyticsData.orderType).toString() ?? '',
    ],
    productIdList: [qohErrorAnalyticsData.skuNumber ?? ''],
    productPartList: [''],
    productPartNumberList: [qohErrorAnalyticsData.partNumber ?? ''],
    productPriceList: [qohErrorAnalyticsData.productPrice.toString() ?? ''],
    productRepositoryIdList: qohErrorAnalyticsData.productRepositoryId.toString(),
    productRequestedQuantityList: [qohErrorAnalyticsData.requestQuantity.toString()],
    qohErrorMessage: [qohErrorAnalyticsData.errorMessage],
    qohErrorMsgType: ['PDP QOH'],
  };
  clickTrack(data);
};

const getSylvaniaVideoNames = (videos: SylvaniaVideoModel[] | undefined) => {
  if (!videos) {
    return '';
  }
  const videoNames: string[] = [];
  videos.forEach((video) => {
    video.videos.forEach((singleVideo) => {
      videoNames.push(singleVideo.title);
    });
  });
  return videoNames.join('|');
};

function ProductDetail() {
  const {
    addToCartCall,
    clearProductPageData,
    changeFindProduct,
    addingToCartStatus,
    productData,
    cartSuccess,
    err: addToCartErr,
    setQuantityInCart,
  } = useAddToCartWithMiniCart();
  const { fulfillmentTypeId, quantity, cartSuccessNote, quantityInCart } = productData;

  const isSuperSkusEnabled = useFeatureFlag('IS_SUPER_SKUS_ENABLED') === 'true';
  const isCertonaEnabledForOosOrDoesNotFit =
    useFeatureFlag('IS_OOS_OR_DOES_NOT_FIT_CONTAINER_ENABLED') === 'true';
  const isUtagDefined = useAnalytics();
  const { pageSpecificData, setPageSpecificData } = usePageSpecificData();
  const { productFindingMethod: productFindingMethodFromHook } = pageSpecificData;
  const callPageView = usePageViewCall();
  useScrollIntoViewDOM([]);
  const preferredVehicle = usePreferredVehicle();
  const router = useRouter();
  const dispatch = useDispatch();
  const lazyMiniCartModalRef = useRef<HTMLDivElement>(null);
  const [analyticsExecuted, setAnalyticsExecuted] = useState(false);
  const [miniCartFlyoutVisible, setMiniCartFlyoutVisible] = useState(false);
  const [certonaRecommendationAddedToCart, setCertonaRecommendationAddedToCart] = useState(false);
  const toggleMiniCartFlyout = () => {
    setMiniCartFlyoutVisible(() => !miniCartFlyoutVisible);
    closeStoreModal();
  };

  const toggleCertonaMiniCartFlyout = () => {
    setShowCertonaMiniCartFlyoutModal(() => !showCertonaMiniCartFlyoutModal);
    closeStoreModal();
  };

  const locale = useLocale();
  const currencySymbol = getCurrencySymbol(locale);
  const [warrantyModal, setWarrantyModal] = useState<WarrantyModal>({ isShown: false });
  const [shouldOpenSpecsPanel, setShouldOpenSpecsPanel] = useState(false);
  const [recommendationsClicked, setRecommendationsClicked] = useState(false);
  const [customizeModalOpen, setCustomizeModalOpen] = useState(false);
  const [addedCertonaItemObject, setAddedCertonaItemObject] =
    useState<AddCertonaItemObjectType | null>(null);
  const [showCertonaMiniCartFlyoutModal, setShowCertonaMiniCartFlyoutModal] =
    useState<boolean>(false);
  /*XM data*/

  const cartSuccessData = cartSuccess;

  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const minicart = headerData?.miniCartSuccessNote;
  const storeNumber = headerData?.storeNumber;
  const vehicleMap = headerData?.vehicleMap;
  const vehicleId = vehicleMap?.catalogVehicleId;
  const hasVehicle =
    !!headerData?.vehicleMap?.vehicleId && headerData?.vehicleMap?.vehicleId !== '';
  const productDetailsQuery = useProductDetails();
  const { refetch: refetchProductData } = productDetailsQuery;
  const taxonomyPath =
    productDetailsQuery?.data?.product?.cmsContentPath ??
    productDetailsQuery?.data?.product?.taxonomyUrl ??
    '';
  const brandName =
    productDetailsQuery.data?.product?.subBrandName ??
    productDetailsQuery.data?.product?.brandName ??
    '';

  const isCSPDPEnabled = productDetailsQuery.isFetched && !productDetailsQuery.isFetching;
  const {
    data: defaultPDPContent,
    isFetched: isDefaultPDPFetched,
    isFetching: isDefaultPDPFetching,
  } = useContentStackDefaultPDPContent({
    enabled: isCSPDPEnabled,
    taxonomyPath,
  });

  const {
    data: premiumPDPContent,
    isFetched: isPremiumPDPFetched,
    isLoading: isPremiumPDPLoading,
  } = useContentStackPremiumPDPContent({
    enabled:
      isCSPDPEnabled &&
      !isDefaultPDPFetching &&
      (defaultPDPContent?.premiumBrands?.brands as string[])?.includes(brandName),
    taxonomyPath,
    brandName,
  });

  useContentStackLivePreview({ enabled: isCSPDPEnabled });

  const isPDPContentFetched =
    !isPremiumPDPLoading && (isDefaultPDPFetched || (isPremiumPDPFetched && taxonomyPath));

  const productDetails = productDetailsQuery.data?.product;
  const productSkuId =
    productDetailsQuery.isSuccess && productDetails?.itemId ? [productDetails?.itemId] : null;
  const installVideosEnabled =
    useFeatureFlag('IS_YMME_INSTALL_VIDEOS_ENABLED') === 'true' &&
    productDetails?.lineCode === 'SYL' &&
    !!preferredVehicle;
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const { data: sylvaniaData, isSuccess: sylvaniaRequestIsSuccess } = useSylvaniaVideos({
    enabled: installVideosEnabled,
    productId: productDetails?.itemId ?? '',
  });
  const productDetailsSEOMetadata = productDetails?.seoMetaData as Record<string, any> | undefined;

  const skuDetailsResult = useProductSkuDetails({
    skuIds: productSkuId,
  });
  const { refetch: refetchSkuDetails } = skuDetailsResult;
  const { data: skuDealsData } = useProductSkuDeals({
    skuIds: productSkuId,
    enabled: isNewDealsApiEnabled,
  });
  const skuDetailsData = skuDetailsResult.isSuccess ? skuDetailsResult.data[0] : null;
  const prevSkuDetailsData = usePrevious(skuDetailsData);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const [dealsAddedToCart, setDealsAddedToCart] = useState(false);
  const [showBundleDeals, setShowBundleDeals] = useState(false);
  const [count, setCount] = useState(skuDetailsData?.quantityInCart || 1);

  const staticPrice = useMemo(() => {
    const currentPrice = getStaticFormattedPrice(
      skuDetailsData?.skuPricingAndAvailability.configurableSku,
      skuDetailsData?.skuPricingAndAvailability
    );
    return Number(currentPrice);
  }, [skuDetailsData?.skuPricingAndAvailability]);

  const [retailPrice, setRetailPrice] = useState<number | string>(() =>
    getFormattedPriceMultiplied(staticPrice, count)
  );
  const [groupId, setGroupId] = useState<
    typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER
  >(0);
  const DLM_PDP_ENABLED = useFeatureFlag('DLM_PDP_ENABLED') === 'true';
  const desktopMiniCartFlyoutEnabled =
    useFeatureFlag('IS_DESKTOP_MINI_CART_FLYOUT_ENABLED') === 'true';
  const mobileMiniCartFlyoutEnabled =
    useFeatureFlag('IS_MOBILE_MINI_CART_FLYOUT_ENABLED') === 'true';
  const tabletMiniCartFlyoutEnabled =
    useFeatureFlag('IS_TABLET_MINI_CART_FLYOUT_ENABLED') === 'true';

  const discountedPrice = isNewDealsApiEnabled
    ? skuDealsData?.[0]?.skuDealsInfo?.discountedPrice
    : skuDetailsData?.skuPricingAndAvailability?.discountedPrice;
  const strikeThroughPricingFlag = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const showStrikeThroughPricing: boolean = strikeThroughPricingFlag && !!discountedPrice;

  const shouldShowDealsButton =
    useFeatureFlag('DEAL_DRAWER_PDP_ENABLED') === 'true' &&
    skuDetailsData?.storeSelected &&
    !showStrikeThroughPricing;

  const redirectPath = productDetailsQuery.data?.redirectURL;

  const orderDetailsReadyStatus = useSelector(
    (state: ReduxState) => state?.orders?.details?.readyStatus
  );

  useHandleRedirect({ redirectPath });

  const pdpBreadcrumb = productDetails?.breadCrumbs;
  const parentShelfPage = pdpBreadcrumb?.find((item) => item.pageType === 'ProductShelf');
  const fitsVehicle = productDetails?.vehicleFitFlag ?? false;
  const vehicleSpecific = productDetails?.vehicleSpecificFlag ?? false;

  /*
     The PDP currently fetches product data on
     the server again after adding an item to cart.
     Certona shouldn't be called again, so this flag is used.
     */
  const [disableCertonaCall, setDisableCertonaCall] = useState(false);
  const defaultFulfillmentOption = useFeatureFlag('DEFAULT_FULFILLMENT_OPTION') || '';
  const crumInfo = (pdpBreadcrumb?.[0]?.displayName ?? '').toLowerCase();
  const isAccessories = accessoriesOptions.includes(crumInfo);
  const orderDetailsFromState = useSelector(selectOrderDetailsFromState);
  const [trackQOHError, setTrackQOHError] = useState(false);
  const [shippingType, setShippingType] = useState<ShippingType>('' as ShippingType);
  const [shippingTypeChanged, setShippingTypeChanged] = useState(false);
  const [storeChanged, setStoreChanged] = useState(false);
  const [fulfillmentId, setFulfillmentId] = useState(0);
  const [oemPartNumber, setOemPartNumber] = useState<string>();
  const [oemBrandName, setOemBrandName] = useState<string>();
  const prevShippingType = usePrevious(shippingType);
  const { workspaceId, previewDate } = useXMPreviewState();
  const itemFulfillmentOptions =
    skuDetailsData?.skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions;
  const productAvailableQuantity =
    itemFulfillmentOptions && getMaxAvailQtyFromOptions(itemFulfillmentOptions);
  const isRedirect = Boolean(productDetailsQuery.data?.redirectURL);
  const previousPath = usePrevious(router.asPath);

  useEffect(() => {
    const handleStoreChanged = () => {
      setStoreChanged(true);
    };
    root.window.addEventListener('preferredStoreUpdated', handleStoreChanged);
    return () => {
      root.window.removeEventListener('preferredStoreUpdated', handleStoreChanged);
    };
  }, []);

  useEffect(() => {
    if (addingToCartStatus === 'loading') {
      setPreventLoadingSkeletonFromShowing(true);
    } else if (productDetailsQuery.isSuccess) {
      setPreventLoadingSkeletonFromShowing(false);
    }
  }, [productDetailsQuery.isSuccess, addingToCartStatus]);

  useEffect(() => {
    if (!!addedCertonaItemObject) {
      setShowCertonaMiniCartFlyoutModal(true);
    }
  }, [addedCertonaItemObject]);

  const store = useStore();

  useEffect(() => {
    let resultsChangesAfterRecommendationsClicked = false;
    if (recommendationsClicked) {
      resultsChangesAfterRecommendationsClicked = skuDetailsData === prevSkuDetailsData;
    }
    let acceptableReadyStatus =
      productDetailsQuery.isSuccess &&
      skuDetailsResult.isSuccess &&
      !isRedirect &&
      !resultsChangesAfterRecommendationsClicked &&
      (!installVideosEnabled || sylvaniaRequestIsSuccess);
    if (!analyticsExecuted && isUtagDefined && pdpBreadcrumb && acceptableReadyStatus) {
      const pdpUtagViewAffinity = sessionStorage.getItem('pdpUtagViewAffinity') === 'true';
      const productFindingMethodFromUtag =
        typeof root.utag_data?.productFindingMethod === 'string'
          ? root.utag_data?.productFindingMethod
          : '';
      const productFindingMethod =
        sessionStorage.getItem('productFindingMethod') ??
        productFindingMethodFromHook ??
        productFindingMethodFromUtag;

      const isSuperSkuPage =
        isSuperSkusEnabled && !!productDetails?.choices && !!productDetails.availableOptions;
      let dynamicPageName = '';

      if (Object.keys(pdpBreadcrumb).length > 0) {
        const pageName = analyticsDynamicPageName(pdpBreadcrumb, siteSectionPdp);
        const skuId = skuDetailsData?.skuPricingAndAvailability.skuId ?? '';
        dynamicPageName = `${pageName}-${skuId}${isSuperSkuPage ? ':SUPER' : ''}`;
      }

      const breadCrumbList = pdpBreadcrumb.map((crumb) => crumb.displayName);
      const categoryLevel1 = breadCrumbList[1] || '';
      const categoryLevel2 = breadCrumbList[2] || '';
      const categoryLevel3 = breadCrumbList[3] || '';
      const categoryLevel4 = breadCrumbList[4] || '';
      const lwTestExperience = sessionStorage.getItem('lwTestExperience');
      const productStrikeThroughPricing = getProductStrikethroughAnalyticValue(
        skuDetailsData?.skuPricingAndAvailability,
        discountedPrice,
        strikeThroughPricingFlag
      );
      const performedSearch = sessionStorage.getItem('performedSearch') === 'true';
      const productRank = isNaN(Number(sessionStorage.getItem('productRank')))
        ? sessionStorage.getItem('productRank')
        : Number(sessionStorage.getItem('productRank'));
      let analyticsViewPayload: AnalyticsViewPayload = {
        categoryLevel1,
        categoryLevel2,
        categoryLevel3,
        categoryLevel4,
        pageName: getPageName(pageTypes.pdp, dynamicPageName),
        siteSection: siteSectionCatalog,
        pageType: pageTypes.pdp,
        searchMethod: sessionStorage.getItem('searchMethod') || '',
        pageHierarchy: `Home|${
          dynamicPageName.includes('-')
            ? dynamicPageName
                .replace(/-/g, ' ')
                .replace(/:/g, '|')
                .replace(/\b([a-z])/g, (x) => x.toUpperCase())
            : dynamicPageName.replace(/\b([a-z])/g, (x) => x.toUpperCase())
        }`,
        ...(productFindingMethod && { productFindingMethod }),
        ...(lwTestExperience && { lwTestExperience }),
        seoPageType: getSeoPageType(pageTypes.pdp, window?.location.pathname),
        ...(sessionStorage.getItem('productRank') !== null &&
          sessionStorage.getItem('productRank') !== undefined && { productRank }),
        seoPopularSearches: !!document.getElementById('#popular-searches-container'),
        seoPopularSearchesClick: sessionStorage.getItem('popularSearchClicked') === 'true',
        ...productDetailAnalytics(store as Store, {
          productDetails,
          skuDetailsData: skuDetailsData,
          autoCorrectedKeyword: '',
          vehicleId: vehicleId ?? '',
          fitsVehicle,
          vehicleSpecific,
          searchRedirectText:
            typeof router.query.searchText === 'string' ? router.query.searchText : '',
          hasDealBadge: shouldShowDealsButton,
          isNewDealsApiEnabled,
          skuDealsData,
        }),
        prodView: eventTypes.prodView,
        skuView: ['1'],
        productStrikeThroughPricing: productStrikeThroughPricing,
        tvVideoName: getSylvaniaVideoNames(sylvaniaData?.sylvaniaVideos),
        videoAvailable: installVideosEnabled ? !!sylvaniaData?.sylvaniaVideos?.length : false,
      };

      //todo use  session storage productFindingMethod  here
      if (pdpUtagViewAffinity) {
        const pageSpecificAnalyticsData: PageSpecificAnalyticsDataType = {
          ...analyticsViewPayload,
          productFindingMethod: 'Affinity',
          pfmLocation: 'Affinity:catalog_product_detail',
        };
        callPageView(
          pageSpecificAnalyticsData,
          getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
        );
        sessionStorage.setItem('pdpUtagLinkAffinity', 'true');
        sessionStorage.removeItem('pdpUtagViewAffinity');
      } else {
        const analyticsDataToRemove =
          !performedSearch && typeof router.query.searchText === 'string'
            ? [...searchAnalyticsKeys, 'eventType']
            : searchAnalyticsKeys;

        callPageView(
          analyticsViewPayload,
          getAnalyticsRelativeComplementOf(analyticsViewPayload, analyticsDataToRemove)
        );
      }
      setPageSpecificData({ productFindingMethod: '' });
      sessionStorage.setItem('popularSearchClicked', 'false');
      sessionStorage.removeItem('searchMethod');
      sessionStorage.removeItem('performedSearch');
      sessionStorage.removeItem('lwTestExperience');
      sessionStorage.removeItem('productRank');
      setAnalyticsExecuted(true);
    } else if (analyticsExecuted && previousPath === router.asPath) {
      sessionStorage.removeItem('productFindingMethod');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addingToCartStatus,
    isUtagDefined,
    pdpBreadcrumb,
    skuDetailsResult.isSuccess,
    productDetailsQuery.isSuccess,
    isRedirect,
    analyticsExecuted,
    store,
    productDetails,
    skuDetailsData,
    vehicleId,
    fitsVehicle,
    vehicleSpecific,
    router.query.searchText,
    DLM_PDP_ENABLED,
    recommendationsClicked,
  ]);

  useEffect(() => {
    if (trackQOHError) {
      if (addingToCartStatus === 'error') {
        setTrackQOHError(false);
        const qohErrorAnalyticsData: QohErrorAnalyticsData = {
          originalPartTypeId: productDetails?.originalPartTypeId ?? '',
          productAvailableQuantity: productAvailableQuantity ? productAvailableQuantity : 0,
          productBrandList: [skuDetailsData?.skuPricingAndAvailability?.brandName ?? ''],
          productCorePriceList: [
            skuDetailsData?.skuPricingAndAvailability?.corePrice.toString() ?? '',
          ],
          orderType: fulfillmentTypeId ?? '',
          skuNumber: skuDetailsData?.skuPricingAndAvailability?.skuId ?? '',
          partNumber: productDetails?.partNumber ?? '',
          productPrice: skuDetailsData?.skuPricingAndAvailability?.retailPrice ?? 0,
          productRepositoryId: [productDetails?.ecommProductId ?? ''],
          requestQuantity: Number(quantity ?? 0),
          errorMessage: addToCartErr ?? '',
        };
        trackPdpQoHFailure(qohErrorAnalyticsData);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingToCartStatus, trackQOHError]);
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  });

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }

    const getPaymentOptionList = async () => {
      await dispatch(fetchPaymentOptions());
    };
    getPaymentOptionList();
  }, [dispatch, locale]);

  const [activeCache, setActiveCache] = useState(false);
  const [showMiniCartModal, setShowMiniCartModal] = useState(false);
  const [showMiniCartFlyoutModal, setShowMiniCartFlyoutModal] = useState(false);
  const [preventLoadingSkeletonFromShowing, setPreventLoadingSkeletonFromShowing] = useState(false);
  const prevStoreNumber = usePrevious(headerData?.storeNumber ?? '');
  const prevVehicleId = usePrevious(vehicleId);
  const checkToOpenMiniCartModal =
    (cartSuccessNote || minicart) && (showMiniCartModal || showMiniCartFlyoutModal);

  const shouldShowLoadingSkeleton =
    !preventLoadingSkeletonFromShowing &&
    (productDetailsQuery.isFetching || skuDetailsResult.isFetching);
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const skuId = skuDetailsData?.skuPricingAndAvailability.skuId ?? '';
  const hasStoreSelected = useIsStoreSelected();
  const isOos =
    hasStoreSelected &&
    (hasVehicle || (!hasVehicle && productDetails?.recordType !== 'app')) &&
    itemFulfillmentOptions?.some((item) => item.available || item.sisterStoreAvailable) === false;
  const doesNotFit =
    hasStoreSelected &&
    hasVehicle &&
    productDetails?.recordType === 'app' &&
    !productDetails.vehicleFitFlag;
  const fittingPartsURL =
    pdpBreadcrumb?.find((item) => item.pageType === 'ProductShelf')?.seoUrl ?? routePaths.blankURL;

  const certonaType =
    isCertonaEnabledForOosOrDoesNotFit && (isOos || doesNotFit)
      ? certonaPageType.pencilpdp
      : !isAccessories
      ? certonaPageType.pdp
      : certonaPageType.accessoriesProductPage;
  const certonaRecommendations = useGetCertonaRecommendations(
    {
      certonaType,
      vehicleID: vehicleId,
      Category1: parentShelfPage?.displayName,
      skuIds: skuId,
      parttype: formatPartTypeId(productDetails?.originalPartTypeId),
    },
    [vehicleId, skuId, isOos, doesNotFit],
    Boolean(
      productDetailsQuery.data &&
        !isRedirect &&
        skuId !== '' &&
        (!disableCertonaCall || prevVehicleId !== vehicleId)
    )
  );

  useAutoCloseOnOrderReady();

  useEffect(() => {
    root.scrollTo(0, 0);
    const jsTrackTimeOut = setTimeout(() => {
      const sortFilterList = document.querySelectorAll('.bv-dropdown-title');

      if (sortFilterList?.length > 0) {
        // .forEach is not supported in IE
        for (const element of sortFilterList) {
          element.addEventListener('click', trackSortFilter);
        }
      }
    }, 4500);
    return function cleanUp() {
      clearProductPageData();
      root.sessionStorage?.removeItem('productType');
      clearTimeout(jsTrackTimeOut);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (minicart || cartSuccessNote) {
      setDisableCertonaCall(true);

      if (isDesktop && !dealDrawerOpen && cartSuccessNote) {
        setShowMiniCartModal(true);
      }

      if (!dealDrawerOpen && cartSuccessNote) {
        setMiniCartFlyoutVisible(true);
        setShowMiniCartFlyoutModal(true);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minicart, cartSuccessNote]);

  useEffect(() => {
    if (!activeCache) {
      setActiveCache(true);
    } // after the inital call, any updates will use active caching

    const storeNumber = headerData?.storeNumber ?? '';
    const vehicleId = headerData?.vehicleMap?.catalogVehicleId ?? '';
    const url = getPathnameFromAsPath(router.asPath);
    const query = router.query;

    if (activeCache && query.vehicleId && vehicleId !== prevVehicleId && !!prevVehicleId) {
      const formattedUrl = stringifyUrl(
        {
          url,
          query: { ...query, vehicleId: null },
        },
        {
          skipNull: true,
        }
      );
      router.push(formattedUrl);
    } else if (
      activeCache &&
      (storeNumber !== prevStoreNumber || vehicleId !== prevVehicleId || !prevVehicleId)
    ) {
      setDisableCertonaCall(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData?.storeNumber, headerData?.vehicleMap, workspaceId, previewDate]);

  useEffect(() => {
    setShowMiniCartModal(false);
    setShowMiniCartFlyoutModal(false);
    setShowCertonaMiniCartFlyoutModal(false);
    setDisableCertonaCall(false);
    setAnalyticsExecuted(false);
    clearProductPageData();
    setShippingTypeChanged(false);
  }, [router.asPath, dispatch, clearProductPageData]);

  useEffect(() => {
    if (sessionStorage.getItem('oemPartNumber')) {
      setOemPartNumber(sessionStorage.getItem('oemPartNumber')!);
    }
    if (sessionStorage.getItem('oemBrandName')) {
      setOemBrandName(sessionStorage.getItem('oemBrandName')!);
    }
    return () => {
      sessionStorage.removeItem('oemPartNumber');
      sessionStorage.removeItem('oemBrandName');
    };
  }, []);

  const submitToCart = () => {
    router.push(routePaths.cart);
  };

  const closeStoreModal = async () => {
    if (!!addedCertonaItemObject) {
      setCertonaRecommendationAddedToCart(false);
      setAddedCertonaItemObject(null);
    } else {
      clearProductPageData();
      setShowMiniCartModal(false);
      setMiniCartFlyoutVisible(false);
      setShowMiniCartFlyoutModal(false);
      setDisableCertonaCall(false);
      await refetchProductData();
      await refetchSkuDetails();
    }
  };

  const [dealDrawerOpen, setDealDrawerOpen] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  const canRenderCMSComparisonChart: boolean = useMemo(() => {
    if (productDetailsQuery.isFetched && isDefaultPDPFetched) {
      const premiumBrands: string[] = defaultPDPContent?.premiumBrands?.brands ?? [];
      const brandName = productDetailsQuery.data?.product?.brandName ?? '';

      return premiumBrands?.includes(brandName)
        ? isPremiumPDPFetched && !premiumPDPContent?.pageBody?.some(hasCMSPremiumTable)
        : true;
    }
    return false;
  }, [
    productDetailsQuery.isFetched,
    isDefaultPDPFetched,
    isPremiumPDPFetched,
    defaultPDPContent?.premiumBrands?.brands,
    productDetailsQuery.data?.product?.brandName,
    premiumPDPContent?.pageBody,
  ]);

  const closeDealsDrawer = useCallback(() => {
    setShowBundleDeals(false);
    isBundleDealDrawerOpen(false);
    setAutoFocus(true);
  }, []);

  const handleDealsAddToCart = useCallback(() => {
    setShippingTypeChanged(false);
    setDealsAddedToCart(true);
  }, []);

  const onContinueShopping = useCallback(async () => {
    await refetchProductData();
    await refetchSkuDetails();
  }, [refetchProductData, refetchSkuDetails]);

  if (productDetailsQuery.isError || skuDetailsResult.isError) {
    return <ErrorPage />;
  }

  const isBundleDealDrawerOpen = (open: boolean) => {
    setDealDrawerOpen(open);
  };

  const showWarrantyModalFromPD = (showModalFrom: string) => {
    setWarrantyModal({ isShown: true, showModalFrom });
  };

  const openConfigurableSku = () => setCustomizeModalOpen(true);

  const brandCode = skuDetailsData?.skuPricingAndAvailability.configurableBrandCode ?? '';
  const productLineCode = skuDetailsData?.skuPricingAndAvailability.productLine ?? '';

  const renderProductCustomizableSKU = () => {
    if (productDetails) {
      const fixedHeaderWrapper = root.document.getElementById('TopHeader');

      if (fixedHeaderWrapper) {
        fixedHeaderWrapper.style.zIndex = '152';
      }

      return (
        <LazyProductCustomizableSKU
          preferredVehicle={preferredVehicle}
          count={count}
          shippingType={shippingType}
          skuId={skuId}
          storeNumber={storeNumber}
          brandCode={brandCode}
          productLineCode={productLineCode}
          productDetails={productDetails}
          customizeModalOpen={customizeModalOpen}
          setCustomizeModalOpen={setCustomizeModalOpen}
          productAvailableQuantity={productAvailableQuantity ?? 0}
        />
      );
    } else {
      return null;
    }
  };

  const renderMiniCart = () => {
    const productImage = productDetails?.itemImageUrl ?? '';
    const productTitle = productDetails?.itemDescription ?? '';

    const productDetailsForMiniCart = {
      productName: {
        productBrand: productDetailsQuery.data?.product?.subBrandName,
        productPart: productDetailsQuery.data?.product?.partTypeName,
        quickNote: productDetailsQuery.data?.product?.quickNote,
      },
      productAvailabilityInfo: skuDetailsResult.data?.[0],
    };

    const renderOldMiniCart = () => {
      return (
        <Hidden only={['sm', 'md']}>
          {showMiniCartModal && (
            <Suspense>
              <LazyMiniCart
                isModalOpen={showMiniCartModal}
                image={productImage}
                successData={cartSuccessData}
                productDetails={productDetailsForMiniCart}
                closeStoreModal={closeStoreModal}
                cartUrl={submitToCart}
                productTitle={productTitle}
                pageName="pdp"
              />
            </Suspense>
          )}
        </Hidden>
      );
    };

    const renderNewFlyoutMiniCart = () => {
      return (
        <Drawer
          data-testid="mini-cart-flyout"
          anchor={'right'}
          open={miniCartFlyoutVisible}
          onClose={toggleMiniCartFlyout}
          PaperProps={{ className: styles.customPaperDrawer }}
          drawerRef={lazyMiniCartModalRef}
        >
          <TrapFocus open={miniCartFlyoutVisible}>
            <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
              <Suspense>
                {showMiniCartFlyoutModal && (
                  <LazyMiniCartFlyoutComp
                    handleClose={toggleMiniCartFlyout}
                    isModalOpen={showMiniCartFlyoutModal}
                    image={productImage}
                    successData={cartSuccessData}
                    productDetails={{
                      productName: productDetailsForMiniCart.productName,
                      productPartType: productDetailsQuery.data?.product?.originalPartTypeId,
                      vehicleFitFlag: productDetailsQuery.data?.product?.vehicleFitFlag,
                      productAvailabilityInfo: {
                        aZBadgesFlagVO:
                          productDetailsForMiniCart.productAvailabilityInfo?.aZBadgesFlagVO,
                        dealsInfo: undefined,
                        skuPricingAndAvailability:
                          productDetailsForMiniCart.productAvailabilityInfo
                            ?.skuPricingAndAvailability,
                      },
                    }}
                    miniCartData={miniCartMap}
                    closeStoreModal={closeStoreModal}
                    cartUrl={submitToCart}
                    productTitle={productTitle}
                    pageName="pdp"
                    orderDetailsFromState={orderDetailsFromState}
                    parentRef={lazyMiniCartModalRef}
                  />
                )}
              </Suspense>
            </div>
          </TrapFocus>
        </Drawer>
      );
    };

    if (checkToOpenMiniCartModal) {
      if (
        (isDesktop && desktopMiniCartFlyoutEnabled) ||
        (isMobile && mobileMiniCartFlyoutEnabled) ||
        (isTablet && tabletMiniCartFlyoutEnabled)
      ) {
        return renderNewFlyoutMiniCart();
      } else if (!isMobile) {
        return renderOldMiniCart();
      }
    }
  };

  return (
    <div className={styles.pdpWrapper}>
      {!isPDPContentFetched && (
        <View padding={{ s: [4, 0], m: [8, 0] }}>
          <ProductAvailabilityInfoSkeleton
            leftContent={<LeftSectionSkeletonLoading />}
            rightContent={<RightSectionSkeletonLoading currencySymbol={currencySymbol} />}
          />
        </View>
      )}
      {isPDPContentFetched && (
        <>
          {productDetailsQuery.isFetched && productDetailsSEOMetadata ? (
            <ProductDetailMetadata
              title={productDetailsSEOMetadata.title}
              metaTitle={productDetailsSEOMetadata.metaTitle}
              url={productDetailsSEOMetadata.url}
              description={productDetailsSEOMetadata.description}
              image={productDetailsSEOMetadata.image}
              siteName={productDetailsSEOMetadata.siteName}
              canonicalURL={productDetailsSEOMetadata.canonicalURL}
              alternateURL={productDetailsSEOMetadata.alternateUrl}
              hrefLang={productDetailsSEOMetadata.refLanguage}
            />
          ) : null}
          {(skuDetailsData?.skuPricingAndAvailability.configurableSku ?? false) &&
            preferredVehicle &&
            customizeModalOpen &&
            renderProductCustomizableSKU()}
          <CMSModularHandler
            content={premiumPDPContent?.pageBody ?? defaultPDPContent?.pageBody}
            certonaType={certonaType}
            certonaRecommendations={certonaRecommendations}
            onCertonaRecommendationClick={() => {
              setRecommendationsClicked(true);
              setAnalyticsExecuted(false);
            }}
            data-testid="cms-modular-handler"
            setAddedCertonaItemObject={setAddedCertonaItemObject}
            ProductDetails={
              <ProductAvailabilityInfo
                key={skuId}
                showWarrantyModal={showWarrantyModalFromPD}
                openConfigurableSku={openConfigurableSku}
                preferredVehicle={preferredVehicle}
                count={count}
                groupId={groupId}
                setGroupId={setGroupId}
                quantityInCart={quantityInCart}
                setQuantityInCart={setQuantityInCart}
                setShowBundleDeals={setShowBundleDeals}
                retailPrice={retailPrice}
                setRetailPrice={setRetailPrice}
                setCount={setCount}
                shippingType={shippingType}
                setShippingType={setShippingType}
                shippingTypeChanged={shippingTypeChanged}
                setShippingTypeChanged={setShippingTypeChanged}
                fulfillmentId={fulfillmentId}
                setFulfillmentId={setFulfillmentId}
                setShouldOpenSpecsPanel={setShouldOpenSpecsPanel}
                oemBrandName={oemBrandName}
                oemPartNumber={oemPartNumber}
                prevShippingType={prevShippingType}
                shouldShowSkeleton={shouldShowLoadingSkeleton}
                productDetails={productDetails}
                productDetailsSuccess={productDetailsQuery.isSuccess}
                productDetailsUpdatedAt={productDetailsQuery.dataUpdatedAt}
                defaultFulfillmentOption={defaultFulfillmentOption}
                skuDealsData={skuDealsData}
                skuDetailsData={skuDetailsData}
                skuDetailsDataLastUpdatedAt={skuDetailsResult.dataUpdatedAt}
                setTrackQOHError={setTrackQOHError}
                changeFindProduct={changeFindProduct}
                refetchProductData={refetchProductData}
                refetchSkuDetails={refetchSkuDetails}
                hasVehicle={hasVehicle ?? false}
                pdpAddToCartSuccess={addingToCartStatus === 'success'}
                addToCartButtonLoading={
                  addingToCartStatus === 'loading' ||
                  orderDetailsReadyStatus === actionTypes.UPDATE_ORDERITEM_REQUESTING
                }
                isBundleDealDrawerOpen={isBundleDealDrawerOpen}
                autoFocus={autoFocus}
                onAddToCartCall={addToCartCall}
                productData={productData}
                xFusionQueryId={productDetailsQuery.data?.xFusionQueryId}
                staticPrice={staticPrice}
                storeChanged={storeChanged}
                setStoreChanged={setStoreChanged}
              />
            }
            warrantyModal={warrantyModal}
            setWarrantyModal={setWarrantyModal}
            shouldOpenSpecsPanel={shouldOpenSpecsPanel}
            setShouldOpenSpecsPanel={setShouldOpenSpecsPanel}
            shouldShowLoadingSkeleton={shouldShowLoadingSkeleton}
            shippingType={shippingType}
            fulfillmentId={fulfillmentId}
            refetchSkuDetails={refetchSkuDetails}
            canRenderComparisonChart={canRenderCMSComparisonChart}
            setCertonaRecommendationAddedToCart={setCertonaRecommendationAddedToCart}
          />
        </>
      )}
      <div className={styles.miniCartPopup}>{renderMiniCart()}</div>
      <div className={styles.miniCartPopup}>
        <Drawer
          data-testid="mini-cart-flyout"
          anchor={'right'}
          open={showCertonaMiniCartFlyoutModal}
          onClose={toggleCertonaMiniCartFlyout}
          PaperProps={{ className: styles.customPaperDrawer }}
          drawerRef={lazyMiniCartModalRef}
        >
          <TrapFocus open={showCertonaMiniCartFlyoutModal}>
            <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
              <Suspense>
                {showCertonaMiniCartFlyoutModal && addedCertonaItemObject && (
                  <LazyMiniCartFlyoutComp
                    handleClose={toggleCertonaMiniCartFlyout}
                    isModalOpen={showCertonaMiniCartFlyoutModal}
                    image={addedCertonaItemObject.productImage}
                    successData={addedCertonaItemObject.cartSuccessData}
                    productDetails={{
                      productName: addedCertonaItemObject.productDetails.productName,
                      certonaItemAddedTocart:
                        certonaRecommendationAddedToCart && isPdpCertonaAddToCartEnabled,
                      originalPartTypeId: addedCertonaItemObject.productDetails.partType,
                      productPartType: productDetails?.partTypeId,
                      productId: productDetails?.ecommProductId,
                      productAvailabilityInfo: {
                        aZBadgesFlagVO: null,
                        dealsInfo: undefined,
                        skuPricingAndAvailability: undefined,
                      },
                    }}
                    miniCartData={miniCartMap}
                    closeStoreModal={closeStoreModal}
                    cartUrl={addedCertonaItemObject.submitToCart}
                    productTitle={addedCertonaItemObject.productTitle}
                    pageName={addedCertonaItemObject.pageName}
                    orderDetailsFromState={orderDetailsFromState}
                    skuId={addedCertonaItemObject.productDetails.skuId}
                    parentRef={lazyMiniCartModalRef}
                  />
                )}
              </Suspense>
            </div>
          </TrapFocus>
        </Drawer>
      </div>
      {locale === countryCodes.mx && <SEOProductSchema />}
      {productDetailsQuery.data?.product?.itemId && (
        <FeatureFlag flag="DLM_PDP_ENABLED">
          <DynamicLinks seoRepositoryId={productDetailsQuery.data.product.itemId} />
        </FeatureFlag>
      )}
      <PreFooter />
      <DealDrawer
        analyticsPageType={analyticsPageType.catalogProductDetail}
        open={showBundleDeals}
        skuId={skuDetailsData?.skuPricingAndAvailability?.skuId}
        skuRetailPrice={retailPrice}
        skuFulfillmentTypeId={fulfillmentId}
        skuQuantity={quantityInCart || 1}
        skuIsVehicleSpecific={productDetails?.vehicleSpecificFlag ?? false}
        closeDealsDrawer={closeDealsDrawer}
        handleDealsAddToCart={handleDealsAddToCart}
        dealsAddedToCart={dealsAddedToCart}
        brand={productDetails?.subBrandName}
        partTypeId={productDetails?.originalPartTypeId}
        productRepositoryId={productDetails?.ecommProductId}
        onContinueShopping={onContinueShopping}
        dealConfigurableLabel={
          isNewDealsApiEnabled
            ? skuDealsData?.[0]?.skuDealsInfo?.dealConfigurableLabel
            : skuDetailsData?.skuPricingAndAvailability.dealConfigurableLabel
        }
        fittingPartsURL={fittingPartsURL}
      />
    </div>
  );
}

export default ProductDetail;
