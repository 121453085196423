/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useRouter } from 'next/router';
import { useProductShelfResults } from './getProductShelfResults';
import { useSearchPartTypes } from '@/features/search/api/getSearchPartTypes';
import { usePageType } from './getPageType';
import { useCategoryResults } from './getCategoryResults';
import { useMemo } from 'react';
import { mapMerchShelfData } from './mapMerchShelfData';
import { mapProductShelfData } from './mapProductShelfData';
import { pageTypes } from '@/constants/page';

const getCombinedStatus = (
  productShelfStatus: 'loading' | 'success' | 'error' | 'idle',
  categoryResultsStatus: 'loading' | 'success' | 'error' | 'idle',
  isMerchShelfPage: boolean
) => {
  if (productShelfStatus === 'success' && isMerchShelfPage) return 'success';
  if (productShelfStatus === 'success' || categoryResultsStatus === 'success') {
    return 'success';
  } else if (categoryResultsStatus === 'loading' || productShelfStatus === 'loading') {
    return 'loading';
  } else if (categoryResultsStatus === 'error' || productShelfStatus === 'error') {
    return 'error';
  } else {
    return 'idle';
  }
};
const CATEGORY_PAGE_TYPES: unknown[] = [
  pageTypes.TopNavCategory,
  pageTypes.LeafCategory,
  pageTypes.CategoryGrouping,
];

export const useMappedCategoryData = (
  { statusOnly }: { statusOnly: boolean } = { statusOnly: false }
) => {
  const router = useRouter();
  const { data: searchPartTypeData } = useSearchPartTypes({
    enabled: false,
  });
  const { data: pageTypeData } = usePageType();
  const isMerchShelfPage = router.route === '/deals/[cmsPath]/[[...slug]]';
  const { data: productShelfData, status: productShelfStatus } = useProductShelfResults({
    enabled: Boolean(pageTypeData?.pageType) || isMerchShelfPage,
    fromRedirect: !!searchPartTypeData?.redirectLocation,
  });

  const { data: categoryResultsData, status: categoryResultsStatus } = useCategoryResults({
    enabled: Boolean(pageTypeData?.pageType),
  });
  const status = getCombinedStatus(
    productShelfStatus as 'loading' | 'success' | 'error' | 'idle',
    categoryResultsStatus as 'loading' | 'success' | 'error' | 'idle',
    isMerchShelfPage
  );

  const category = useMemo(() => {
    if (statusOnly) {
      return undefined;
    }

    if (isMerchShelfPage && productShelfData) {
      return mapMerchShelfData(productShelfData);
    } else if (CATEGORY_PAGE_TYPES.includes(pageTypeData?.pageType) && categoryResultsData) {
      const breadCrumbsList = categoryResultsData.breadCrumbs;

      return {
        ...categoryResultsData,
        ...(breadCrumbsList && { breadCrumbsList }),
        redirectPage: categoryResultsData.redirectLocation,
      };
    } else if (productShelfData) {
      return {
        redirectPage: productShelfData.redirectLocation,
        ...mapProductShelfData(productShelfData, pageTypeData),
      };
    }
  }, [categoryResultsData, productShelfData, isMerchShelfPage, pageTypeData, statusOnly]);
  const data = status === 'success' ? category : undefined;
  return {
    data,
    status,
    categoryResultsStatus,
  };
};
