/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext } from 'react';
import type { FlyoutProductDetails, ShippingType } from '../interface/interface';

export type FlyoutDataState = {
  dealId?: string;
  productDetails?: FlyoutProductDetails;
  quantityInCart: number;
  shippingType: ShippingType;
  skuId: string;
};

export type SisterStoreContextValue = {
  setUsingAutoClose: (isUsingAutoClose: boolean) => void;
  toggleSisterStoreFlyout: (data?: FlyoutDataState | undefined) => void;
  openSisterStoreFlyout: (data: FlyoutDataState) => void;
  updateSettingNewStore: (isLoading: boolean) => void;
  setSisterStoreFlyoutIsOpen: (isOpen: boolean) => void;
  closeSisterStoreFlyout: () => void;
  isSisterStoreFlyoutOpen: boolean;
  isUsingAutoClose: boolean;
  isSettingNewStore: boolean;
};

export const SisterStoreContext = createContext<SisterStoreContextValue>({
  setUsingAutoClose: () => {},
  openSisterStoreFlyout: () => {},
  toggleSisterStoreFlyout: () => {},
  updateSettingNewStore: () => {},
  setSisterStoreFlyoutIsOpen: () => {},
  closeSisterStoreFlyout: () => {},
  isSisterStoreFlyoutOpen: false,
  isUsingAutoClose: false,
  isSettingNewStore: false,
});
