/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CategoryData, ProductShelfResponse } from '../interface';
import type { PageTypeData } from '@/types';
import { getMapFacet } from '@/hooks/useMapFacet';
import type { ShelfLeftNav } from '@/types/legacy';
import { getShopByPageName } from '../utils/getShopByPageName';

export const mapProductShelfData = (
  productShelfData: ProductShelfResponse,
  pageTypeData: PageTypeData | null | undefined
): CategoryData => {
  const makeorModelName = [pageTypeData?.make, pageTypeData?.model].filter(Boolean).join(' ');
  return {
    ...productShelfData,
    makeModelYearPageType: getShopByPageName(pageTypeData),
    shopByUrlMake: pageTypeData?.make || '',
    shopByUrlYear: pageTypeData?.year || '',
    shopByUrlModel: pageTypeData?.model || '',
    makeorModelName,
    productId: productShelfData.productShelfResults?.productId || '',
    breadCrumbsList:
      productShelfData.productShelfResults?.breadCrumbs?.map((item) => ({
        pageType: item.pageType,
        displayName: item.displayName,
        url: item.canonicalPath,
      })) ?? [],
    contents: {
      'endeca:contentPath': '',
      '@type': '',
      templateTypes: [],
      name: '',
      contentPaths: [],
      redirectGroup: '',
      contents: [
        {
          '@type': 'Shelf',
          includeInSiteMap: 'true',
          name: 'Shelf Page',
          categoryTippyTop: [],
          categoryPageBody: [],
          categoryResultHeader: [],
          categoryResultBottom: [],
          categoryHead: [],
          isBrandPremiumPage: false,
          shelfTippyTop: [{ '@type': '' }],
          shelfLeftNav: [
            {
              '@type': 'ShelfLeftNav_FilterNav',
              staticNavigation:
                productShelfData.productShelfResults?.facets?.map((facet) =>
                  getMapFacet(facet, 'Clearance')
                ) ?? [],
              name: '',
            },
            {},
            {},
          ] as ShelfLeftNav,
          shelfHead: [],
          shelfResultBottom: [
            { '@type': '', contentPaths: [], contents: [], name: '', templateTypes: [] },
            {
              '@type': '',
              desktop: false,
              mmEnabled: false,
              mobile: false,
              name: '',
              seoCopyBlock: '',
              seoTextMM: '',
              seoTextYMM: '',
              tablet: false,
              ymmEnabled: false,
            },
          ],
          shelfResultHeader: [],
          shelfPageBody: [
            {
              '@type': 'ShelfPageBody_ResultsList',
              name: 'Results List',
              tablet: true,
              desktop: true,
              mobile: true,
              disableRecordsPerPage: false,
              headerText: productShelfData.productShelfResults?.pageDisplayName ?? '',
              recsPerPage: productShelfData.productShelfResults?.recordsPerPage ?? 0,
              totalNumRecs: productShelfData.productShelfResults?.totalNumberOfRecords ?? 0,
              firstRecNum: productShelfData.productShelfResults?.firstRecordNumber ?? 0,
              lastRecNum: productShelfData.productShelfResults?.lastRecordNumber ?? 0,
              rppOptions: undefined,
              records: productShelfData.productShelfResults?.skuRecords?.map((item) => {
                return {
                  active: item.activeFlag,
                  productReviewsEnabled: item.reviewEnabledFlag,
                  techNote: item.technicalNote,
                  brand: item.brandName ?? '',
                  description: item.itemDescription,
                  locationFilter: item.itemLocation,
                  seoUrl: item.productDetailsPageUrl,
                  configurableSku: item.configurableSkuFlag,
                  productImageUrl: item.itemImageUrl ?? '',
                  imageUrl: item.itemImageUrl ?? '',
                  normalizedProductCanonicalUrl: item.productCanonicalUrl,
                  productSpecs: item.productSpecification,
                  name: item.itemDescription,
                  techNotes: item.applicationQuestions,
                  vehicleSpecific: item.vehicleSpecificFlag,
                  vehicleFit: !!item.vehicleFitmentFlag,
                  skuNumber: Number(item.itemId),
                  ...item,
                  partTypeId: item.originalPartTypeId ?? '',
                  originalPartTypeId: item.originalPartTypeId ?? '',
                  systemCode:
                    typeof item.originalSystemCode === 'string'
                      ? parseInt(item.originalSystemCode)
                      : item.originalSystemCode,
                  originalSystemCode:
                    typeof item.originalSystemCode === 'string'
                      ? parseInt(item.originalSystemCode)
                      : item.originalSystemCode,
                };
              }),
            },
          ],
        },
      ],
    },
  };
};
