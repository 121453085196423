/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import azURL, { relPath } from '@/config/serviceAPI';
import { getAxiosForClient } from '@/lib/axios';
import { useMutation } from '@tanstack/react-query';
import type { StoreData } from '../interface/interface';

const URL = azURL(relPath.findNearByStores);

type RequestData = {
  storeNumber: string | undefined;
  range: string;
  skuId: string;
  resultsLimit: string;
};

const postSisterStores = async (values: RequestData) => {
  const response = await getAxiosForClient().post<StoreData>(URL, values);
  return selector(response.data);
};

const selector = (data: StoreData): StoreData => {
  return data;
};

export const useFetchSisterStoreByStoreNumber = () => {
  return useMutation({
    mutationFn: (values: RequestData) => postSisterStores(values),
  });
};
