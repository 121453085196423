/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const infoIcon = {
  src: '/images/info.svg',
  alt: 'info',
};

export const whiteInfoIcon = {
  src: '/images/info-white.svg',
  alt: 'info-white',
};
