/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { cartConstants } from '@/constants/cart';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { type ShelfFulfillmentOptions } from '@/types/legacy';
import { type FulfillmentOption, type FulfillmentGroup } from '@/types/reduxStore/skuDetails';

const getByGroupId = (options: FulfillmentGroup[], groupId: number) => {
  return options.find((option) => option.groupId === groupId)?.groupFulfillmentOptions ?? [];
};

const findOptionByTypeId = (options: FulfillmentOption[], typeId: number) =>
  options.find((option) => option.fulfillmentTypeIdOriginal === typeId);

/**
 * Provides relevant information to display fulfillment options in a button-style layout.
 * The array returned is in the same order as the buttons should be displayed.
 * If an option is `undefined`, it means that option was not found and its corresponding button
 * should likely be undefined or hidden.
 *
 * @param {FulfillmentGroup[] | undefined} fulfillmentOptions - Array of fulfillment groups containing options
 * @returns {(FulfillmentOption | undefined)[]} Array containing:
 * - Store pickup option (if it exists): Can guide how to display the store option (e.g., available or not).
 * - One prioritized online delivery option, helping determine the second button:
 *   1. Next Day Delivery
 *   2. VDP DS
 *   3. Standard Online Order
 *   4. Other delivery types
 * - Same day delivery option (if it exists): Useful for calculations or dedicated button display.
 *
 * @note This function provides the relevant information to decide the display of buttons
 *       and assists in calculations. Includes options even if they are not currently available.
 */
export const determineOptionsToDisplayButtons = (
  fulfillmentOptions: FulfillmentGroup[] | undefined
): Array<FulfillmentOption | undefined> => {
  const options = fulfillmentOptions ?? [];

  const storeOptions = getByGroupId(options, FULFILLMENT_METHODS.STOREORDER);
  const onlineOptions = getByGroupId(options, FULFILLMENT_METHODS.ONLINEORDER);

  const storeOption = storeOptions[0];

  const onlineOptionToDisplay =
    findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.NEXTDAY) || // Prioritized first
    findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.VDPDS) || // Second priority
    findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.ONLINEORDER) || // Third priority
    // Any other online delivery option, excluding Same Day
    onlineOptions.find((opt) => opt.fulfillmentTypeIdOriginal !== FULFILLMENT_METHODS.SAMEDAY);

  const sameDayOption = findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.SAMEDAY);

  return [storeOption, onlineOptionToDisplay, sameDayOption];
};

/**
 * Determines the index of the selected fulfillment button based on shipping type and fulfillment ID.
 * Returns the index (0-based) of the button that should be selected in the fulfillment options list.
 *
 * @param {string} shippingType - The type of shipping selected (store order or online order)
 * @param {number} fulfillmentId - The ID of the fulfillment method
 * @returns {number} Index of the button that should be selected
 */
export const determineIndexButtonToBeSelected = (shippingType: string, fulfillmentId: number) => {
  const storePickUpShouldBeSelected = shippingType === cartConstants.STOREORDER;
  const homeDeliveryShouldBeSelected =
    shippingType === cartConstants.ONLINEORDER && fulfillmentId !== FULFILLMENT_METHODS.SAMEDAY;
  const sameDayDeliveryShouldBeSelected =
    shippingType === cartConstants.ONLINEORDER && fulfillmentId === FULFILLMENT_METHODS.SAMEDAY;
  const optionsSelectedStates = [
    storePickUpShouldBeSelected,
    homeDeliveryShouldBeSelected,
    sameDayDeliveryShouldBeSelected,
  ];
  return optionsSelectedStates.findIndex((item) => item === true);
};

/**
 * Provides relevant information for displaying fulfillment options in a list-style layout.
 * This function focuses on available options and limits the display to two online delivery options.
 *
 * @param {FulfillmentGroup[] | undefined} fulfillmentOptions - Array of fulfillment groups containing options
 * @returns {(FulfillmentOption | undefined)[]} Array containing:
 * - Store pickup option: Always included and can guide how to display it in the list.
 * - Up to two available online delivery options: Helps determine the order and number of displayed options.
 *
 */
export const determineOptionsToDisplayList = (
  fulfillmentOptions: FulfillmentGroup[] | undefined
): Array<FulfillmentOption | undefined> => {
  const options = fulfillmentOptions ?? [];

  const storeOption = getByGroupId(options, FULFILLMENT_METHODS.STOREORDER)[0];

  const availableOnlineOptions = getByGroupId(options, FULFILLMENT_METHODS.ONLINEORDER)
    .filter((option) => option.available)
    .slice(0, 2);

  return [storeOption, ...availableOnlineOptions];
};

export const determineIfOptionIsSelectable = (option: FulfillmentOption | undefined): boolean => {
  if (!option) {
    return false;
  }
  return option.available && option.availableQty > 0;
};

/**
 * Finds a fulfillment option by its type ID and checks if it is selectable.
 *
 * @param options - An array of fulfillment options.
 * @param typeId - The type ID of the fulfillment option to find.
 * @returns A boolean indicating whether the found option is selectable.
 */
const findAndCheckIfOptionIsSelectable = (
  options: FulfillmentOption[],
  typeId: number
): boolean => {
  const option = findOptionByTypeId(options, typeId);
  return determineIfOptionIsSelectable(option);
};

/**
 * Determines the default selected fulfillment option based on the provided fulfillment options
 * and the default fulfillment option preference.
 *
 * @param {ShelfFulfillmentOptions[] | undefined} fulfillmentOptions - The list of available fulfillment options.
 * @param {string} defaultFulfillmentOption - The default fulfillment option preference ('BOPUS' or 'STH').
 * @returns {number | undefined} - The selected fulfillment method ID or undefined if no valid option is found.
 */
export const selectPreferredFulfillmentOptionForList = (
  fulfillmentOptions: ShelfFulfillmentOptions[] | undefined,
  defaultFulfillmentOption: string
): number | undefined => {
  if (
    !fulfillmentOptions ||
    (defaultFulfillmentOption !== 'BOPUS' && defaultFulfillmentOption !== 'STH')
  ) {
    return undefined;
  }

  const storeOptions = getByGroupId(fulfillmentOptions, FULFILLMENT_METHODS.STOREORDER);
  const onlineOptions = getByGroupId(fulfillmentOptions, FULFILLMENT_METHODS.ONLINEORDER);

  const isBopusSelectable = findAndCheckIfOptionIsSelectable(
    storeOptions,
    FULFILLMENT_METHODS.STOREORDER
  );

  const atLeastOneOnlineOptionIsAvailable = onlineOptions.some((option) =>
    determineIfOptionIsSelectable(option)
  );

  if (
    (defaultFulfillmentOption === 'BOPUS' && isBopusSelectable) ||
    (defaultFulfillmentOption === 'STH' && !atLeastOneOnlineOptionIsAvailable)
  ) {
    return FULFILLMENT_METHODS.STOREORDER;
  }

  const prioritizedOnlineMethods = [
    FULFILLMENT_METHODS.NEXTDAY,
    FULFILLMENT_METHODS.SAMEDAY,
    FULFILLMENT_METHODS.VDPDS,
    FULFILLMENT_METHODS.VDP,
    FULFILLMENT_METHODS.ONLINEORDER,
  ];

  for (const method of prioritizedOnlineMethods) {
    if (findAndCheckIfOptionIsSelectable(onlineOptions, method)) {
      return method;
    }
  }

  return undefined;
};
