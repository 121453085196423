/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createRef, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import azCommonStyles from '../../theme/globals.module.scss';
import CustomRadioInput from '../AZCustomComponent/FormGroup/RadioButton';
import { FULFILLMENT_METHODS } from '../../constants/fulfillmentConstants';
import { cartConstants } from '../../constants/cart';
import styles from './styles.module.scss';
import { SubGroupOptions } from './SubGroupOptions';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { type ShippingType } from '@/features/fulfillment';

type Props = {
  itemData?: ItemData;
  fulfillmentOptionsGroupList: Array<FulfillmentGroup>;
  changeShippingType: (
    shippingType: ShippingType,
    groupId: typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER,
    maxAvailableQty: number,
    sisterStoreAvailable: boolean
  ) => void;
  shippingType: ShippingType;
  skuId: string | undefined;
  productName: string;
  sisterStoreAvailable?: boolean;
  handleInstockAnalytics?: () => any;
};
type ItemData = {
  shipToHome: boolean;
  itemQuantity: number;
};

export default function FulfillmentOptionsV2({
  changeShippingType,
  fulfillmentOptionsGroupList,
  productName,
  shippingType,
  skuId,
  itemData,
  handleInstockAnalytics,
}: Props) {
  const inline = false;

  const handleChange = (item: FulfillmentGroup) => {
    if (item.available) {
      changeShippingType(
        item.groupId === FULFILLMENT_METHODS.STOREORDER
          ? (cartConstants.STOREORDER as 'STOREORDER')
          : (cartConstants.ONLINEORDER as 'ONLINEORDER'),
        item.groupId,
        item.maxAvailableQty,
        false
      );
    }
  };

  const subGroupRefs = fulfillmentOptionsGroupList?.map(() => ({ ref: createRef<any>() }));
  const [ariaLabels, setAriaLabels] = useState<Array<string>>([]);
  const determineAriaLabel = useCallback(() => {
    const ariaLabel: string[] = [];
    const inStockLabel =
      subGroupRefs[0]?.ref.current?.querySelector('[data-testid^="availability-"]')?.innerHTML ||
      '';
    const storeLabel = subGroupRefs[0]?.ref.current?.querySelector('button')?.innerHTML || '';
    const nextDayDelivery =
      subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="next-day"]')
        ?.innerHTML || '';
    const sameDayDelivery =
      subGroupRefs[1]?.ref.current?.children[1]?.querySelector('[data-testid="same-day"]')
        ?.innerHTML || '';
    const standardDelivery =
      subGroupRefs[1]?.ref.current?.querySelector('[data-testid="standard-delivery-item"]')
        ?.innerHTML || '';
    let sameDayDeliveryText = '';
    let nextDayDeliveryText = '';
    let standardDeliveryText = '';
    if (nextDayDelivery || sameDayDelivery) {
      const orderWithin =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="orderby"]')
          ?.innerHTML || '';
      const nextDaycountdownHours =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="countdown-hours"]'
        )?.innerHTML || '';
      const nextDayestimatedDelivery =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="estimated-delivery-date-label"]'
        )?.innerHTML || '';
      const nextDayestimatedDeliveryDate =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="estimated-delivery-date"]'
        )?.innerHTML || '';
      nextDayDeliveryText = `${nextDayDelivery} ${orderWithin} ${nextDaycountdownHours} ${nextDayestimatedDelivery} ${nextDayestimatedDeliveryDate} `;

      if (sameDayDelivery) {
        const sameDayDelivery =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector('[data-testid="same-day"]')
            ?.innerHTML || '';
        const orderBy =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector('[data-testid="orderby"]')
            ?.innerHTML || '';
        const sameDaycountdownHours =
          subGroupRefs[1]?.ref.current?.children[1]?.querySelector(
            'span[data-testid="countdown-ampm"]'
          )?.innerHTML || '';
        const sameDayestimatedDelivery =
          subGroupRefs[1]?.ref.current?.children[1].querySelector(
            'span[data-testid="estimated-delivery-date-label"]'
          )?.innerHTML || '';
        const sameDayestimatedDeliveryDate =
          subGroupRefs[1]?.ref.current?.children[1].querySelector(
            'span[data-testid="estimated-delivery-date"]'
          )?.innerHTML || '';
        sameDayDeliveryText = `${sameDayDelivery} ${orderBy} ${sameDaycountdownHours} ${sameDayestimatedDelivery} ${sameDayestimatedDeliveryDate} `;
      }
    }
    if (!sameDayDelivery) {
      const sameDayDelivery =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="same-day"]')
          ?.innerHTML || '';
      const orderBy =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector('[data-testid="orderby"]')
          ?.innerHTML || '';
      const sameDaycountdownHours =
        subGroupRefs[1]?.ref.current?.children[0]?.querySelector(
          'span[data-testid="countdown-ampm"]'
        )?.innerHTML || '';
      const sameDayestimatedDelivery =
        subGroupRefs[1]?.ref.current?.children?.[0]?.querySelector(
          'span[data-testid="estimated-delivery-date-label"]'
        )?.innerHTML || '';
      const sameDayestimatedDeliveryDate =
        subGroupRefs[1]?.ref.current?.children?.[0]?.querySelector(
          'span[data-testid="estimated-delivery-date"]'
        )?.innerHTML || '';
      sameDayDeliveryText = `${sameDayDelivery} ${orderBy} ${sameDaycountdownHours} ${sameDayestimatedDelivery} ${sameDayestimatedDeliveryDate} `;
    }

    if (standardDelivery) {
      const estimatedDelivery =
        subGroupRefs[1]?.ref.current?.querySelector('[data-testid="estimated-delivery-standard"]')
          ?.innerHTML || '';
      const estimatedDeliveryDate =
        subGroupRefs[1]?.ref.current?.querySelector(
          '[data-testid="estimated-delivery-date-standard"]'
        )?.innerHTML || '';
      standardDeliveryText = ' ' + standardDelivery + estimatedDelivery + estimatedDeliveryDate;
    }
    const fullfillOption1 = `${fulfillmentOptionsGroupList[0]?.groupLabel} ${inStockLabel} ${storeLabel}`;

    const fullfillOption2 = `${fulfillmentOptionsGroupList[1]?.groupLabel} ${nextDayDeliveryText} ${sameDayDeliveryText} ${standardDeliveryText}`;
    ariaLabel.push(fullfillOption1);
    ariaLabel.push(fullfillOption2);
    setAriaLabels([...ariaLabel]);
  }, [fulfillmentOptionsGroupList, subGroupRefs]);

  useEffect(() => {
    if (fulfillmentOptionsGroupList) {
      determineAriaLabel();
    }
    // if adding determinAriaLabel as dependecy causes stack overflow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.toggleContainer}>
      {fulfillmentOptionsGroupList
        ? fulfillmentOptionsGroupList.map((item, buttonIndex) => {
            const optionChecked =
              item.groupId ===
              (shippingType === cartConstants.STOREORDER
                ? FULFILLMENT_METHODS.STOREORDER
                : FULFILLMENT_METHODS.ONLINEORDER);
            const hideRadioButton = !item.available;
            return (
              <div
                key={item?.groupLabel}
                className={styles.segmentedToggleContainer}
                data-testid={`toggle-container-${buttonIndex}`}
              >
                <div className={`${inline ? styles.inlineToggle : styles.segmentedToggle}`}>
                  <CustomRadioInput
                    inputDataTestId={`optionButton${buttonIndex}`}
                    name={`fullfilment-radio${buttonIndex}`}
                    value={item.groupId}
                    disabled={!item.available}
                    checked={optionChecked}
                    onClick={(e) => {
                      hideRadioButton && e.preventDefault();
                    }}
                    onChange={() => handleChange(item)}
                    label={
                      <div
                        className={cx(
                          azCommonStyles['az-caption'],
                          azCommonStyles['az-align-left'],
                          styles.deliveryGroupLabel
                        )}
                        data-testid="delivery-option-label"
                        aria-hidden="true"
                      >
                        {item.groupLabel}
                      </div>
                    }
                    ariaLabel={ariaLabels[buttonIndex]}
                    subLabel={
                      item.groupFulfillmentOptions ? (
                        <SubGroupOptions
                          groupFulfillmentOptions={item.groupFulfillmentOptions}
                          itemData={itemData}
                          skuId={skuId}
                          productName={productName}
                          groupId={item.groupId}
                          handleInstockAnalytics={handleInstockAnalytics}
                          ref={subGroupRefs[buttonIndex].ref}
                        />
                      ) : null
                    }
                    hideRadioButton={hideRadioButton}
                  />
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
