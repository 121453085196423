/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type ProductModel } from '@/api/types/products-types';
import SuperSkuStyles from './SuperSkuStyles.module.scss';
import cx from 'classnames';
import { View, Text, Actionable } from '@az/starc-ui';
import { useRouter } from 'next/router';
import Image from '@/components/NextImage';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';

const SCROLL_BY_AMOUNT = 80;
// small threshold to account for decimal rounding
const SCROLL_THRESHOLD = 3;

type Props = {
  optionName: string;
  availableOptions?: ProductModel['availableOptions'];
  choices?: ProductModel['choices'];
  currentSelection?: Record<string, string>;
};

const SuperSkuOptionSelector = ({
  optionName,
  availableOptions,
  choices,
  currentSelection,
}: Props) => {
  const router = useRouter();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [shouldHideRightArrow, setShouldHideRightArrow] = useState(true);
  const [shouldHideLeftArrow, setShouldHideLeftArrow] = useState(true);
  const { setPageSpecificData } = usePageSpecificData();
  const options = availableOptions?.[optionName];

  const checkScrollPosition = () => {
    if (optionsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = optionsRef.current;

      // Hide left arrow when scrolled all the way to the left
      setShouldHideLeftArrow(scrollLeft <= 0);

      // Hide right arrow when scrolled all the way to the right
      setShouldHideRightArrow(
        Math.ceil(scrollLeft + clientWidth + SCROLL_THRESHOLD) >= scrollWidth
      );
    }
  };

  const scroll = (offset: number) => {
    const scrollTo = (optionsRef.current?.scrollLeft ?? 0) + offset;
    optionsRef.current?.scrollTo({ left: scrollTo, behavior: 'smooth' });
  };

  // Determines which options are disabled given the current selection
  const enabledOptionValues = useMemo<string[]>(() => {
    if (Object.getOwnPropertyNames(currentSelection).length < 2) {
      return choices?.map((choice) => choice.choice[optionName]) || [];
    }

    let enabledOptionValues = new Array<string>();
    const otherOptionNames = Object.getOwnPropertyNames(currentSelection).filter(
      (key) => key !== optionName
    );

    otherOptionNames.forEach((otherOptionName) => {
      const enabledChoices = choices?.filter(
        (choice) => choice.choice[otherOptionName] === currentSelection?.[otherOptionName]
      );
      enabledOptionValues = enabledChoices?.map((choice) => choice.choice[optionName]) || [];
    });
    return enabledOptionValues;
  }, [choices, currentSelection, optionName]);

  const handleOptionClick = (option: { selected: boolean; value: string }) => {
    if (option.selected) {
      return;
    }

    const newSelection = {
      ...currentSelection,
      [optionName]: option.value,
    };

    const newChoice = choices?.find(({ choice }) =>
      Object.entries(newSelection).every(([key, value]) => choice[key] === value)
    );

    if (newChoice) {
      setPageSpecificData({
        productFindingMethod: 'Super SKU',
      });
      router.push(newChoice.choice.productDetailsPageUrl);
    }
  };

  useEffect(() => {
    checkScrollPosition();
  }, []);

  useEffect(() => {
    const container = optionsRef.current;
    container?.addEventListener('scroll', checkScrollPosition);
    container?.addEventListener('touch', checkScrollPosition);

    return () => {
      container?.removeEventListener('scroll', checkScrollPosition);
      container?.removeEventListener('touch', checkScrollPosition);
    };
  }, []);

  return (
    <View direction="column" className={SuperSkuStyles.superSkuOptionSelector}>
      <View direction="row" attributes={{ 'data-testid': 'super-sku-selected-option' }}>
        <Text weight="bold" className={SuperSkuStyles.optionName}>
          {optionName}:
        </Text>
        <Text className={SuperSkuStyles.selectedOption}>{currentSelection?.[optionName]}</Text>
      </View>
      <View direction="row" align="center" className={SuperSkuStyles.optionsContainer}>
        <View
          direction="row"
          align="center"
          wrap={false}
          className={SuperSkuStyles.options}
          attributes={{ ref: optionsRef, 'data-testid': 'options-container' }}
        >
          {options?.map((option) => (
            <Actionable
              key={option.value}
              disabled={!enabledOptionValues.includes(option.value)}
              onClick={() => handleOptionClick(option)}
              className={cx(SuperSkuStyles.optionButton, {
                [SuperSkuStyles.selectedOptionButton]: option.selected,
                [SuperSkuStyles.disabledOptionButton]: !enabledOptionValues.includes(option.value),
              })}
            >
              {option.value}
            </Actionable>
          ))}
        </View>
        <Actionable
          attributes={{ 'data-testid': 'scroll-left-button' }}
          onClick={() => scroll(-SCROLL_BY_AMOUNT)}
          className={cx(SuperSkuStyles.arrowButton, SuperSkuStyles.arrowButtonLeft, {
            [SuperSkuStyles.hidden]: shouldHideLeftArrow,
          })}
        >
          <Image
            src="/images/left-arrow-grey.svg"
            alt="scroll carousel left"
            height={16}
            width={8}
          />
        </Actionable>
        <Actionable
          attributes={{ 'data-testid': 'scroll-right-button' }}
          onClick={() => scroll(SCROLL_BY_AMOUNT)}
          className={cx(SuperSkuStyles.arrowButton, SuperSkuStyles.arrowButtonRight, {
            [SuperSkuStyles.hidden]: shouldHideRightArrow,
          })}
        >
          <Image
            src="/images/left-arrow-grey.svg"
            alt="scroll carousel right"
            height={16}
            width={8}
          />
        </Actionable>
      </View>
    </View>
  );
};

export default SuperSkuOptionSelector;
