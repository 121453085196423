/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const autoZoneRewardsIcon = {
  src: '/images/az-rewards-icon.svg',
  alt: 'AutoZone Rewards Icon',
};
