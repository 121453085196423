/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useState } from 'react';
import { SisterStoreTile } from './SisterStoreTile';
import styles from './flyout.module.scss';
import cx from 'classnames';
import type { StoreData, SisterStore } from '../../interface/interface';

type SisterStoreFlyoutListProps = {
  sisterStoresData: StoreData;
  skuId: string;
  productDetails:
    | {
        productInfo?: {
          productId?: string;
        };
        skuDescription?: string;
      }
    | undefined;
  filterproduct:
    | {
        name?: string;
      }
    | undefined;
  count: number;
  labelMap: Record<string, string>;
  quantityInCart: number;
  filterChecked: boolean;
  limitCount: number;
  sisterStoreConstraint: string[];
  dealId?: string;
  sisterStoreDisplayList: SisterStore[];
  indexToFocus: number;
  prevStore?: string;
};

export const SisterStoreFlyoutList = ({
  sisterStoresData,
  skuId,
  productDetails,
  filterproduct,
  count,
  labelMap,
  quantityInCart,
  filterChecked,
  limitCount,
  sisterStoreConstraint,
  dealId,
  sisterStoreDisplayList,
  indexToFocus,
  prevStore,
}: SisterStoreFlyoutListProps) => {
  const [sisterStoreList, setSisterStoreList] = useState<SisterStore[]>();
  useEffect(() => {
    if (sisterStoresData !== undefined) {
      setSisterStoreList(sisterStoresData.sisterStores);
    }
  }, [sisterStoresData]);

  const calculateFilter = (sisterStore: SisterStore, index: number) => {
    /*
      if qoh is zero return false
      else if filter is checked, then return true
      else return true if count less than/equal to qoh
    */
    const filtered =
      sisterStore.qoh > 0 ? (filterChecked ? true : count <= sisterStore.qoh) : false;
    const constrained =
      dealId && sisterStoreConstraint
        ? sisterStoreConstraint.includes(sisterStore.sisterStoreDetail.storeNumber)
        : true;

    const visibleIndex = sisterStoreDisplayList.findIndex(
      (displayStore) =>
        displayStore.sisterStoreDetail.storeNumber === sisterStore.sisterStoreDetail.storeNumber
    );

    return (
      filtered &&
      constrained &&
      visibleIndex != -1 &&
      (index < limitCount || visibleIndex < limitCount)
    );
  };

  return (
    <div className={styles.sisterStoreList}>
      <ul role={'list'}>
        {sisterStoreList
          ?.filter((sisterStore) => sisterStore.qoh > 0)
          .map((sisterStore, index) => (
            <li
              key={index}
              role={'listitem'}
              className={cx(
                styles.animate,
                calculateFilter(sisterStore, index) ? '' : styles.animateHidden
              )}
            >
              <SisterStoreTile
                sisterStore={sisterStore}
                skuId={skuId}
                productDetails={productDetails}
                filterproduct={filterproduct}
                count={count}
                dealId={dealId}
                labelMap={labelMap}
                quantityInCart={quantityInCart}
                removeTabbableItems={!calculateFilter(sisterStore, index)}
                indexToFocus={indexToFocus > 0 && index === indexToFocus}
                prevStore={prevStore}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SisterStoreFlyoutList;
