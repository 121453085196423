/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import styles from './styles.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';
import Skeleton from '@/components/Skeleton/Skeleton';
import CartCommonCounterPart from '@/components/Cart/CommonCounterPart';
import PdpPrice from '../PdpPrice';
import { countryCodes } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import type {
  AvailabilityInfoVOFromAPIV2,
  SkuPricingAndAvailabilityFromAPI,
} from '@/types/availability';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import PriceInfoPopover from '../PriceInfoPopover';
import CorePriceInfoPopOver from '../CorePriceInfoPopover';
import CorePrice from '@/components/AZCustomComponent/CorePrice';
import Price from '@/components/AZCustomComponent/Price';
import { type ReduxState } from '@/types';
import { useSelector } from 'react-redux';
import { getPopoverPrices } from './utils/getPopoverPrices';
import { getDealPrice } from './utils/getDealPrice';
import { type ShippingType } from '@/features/fulfillment';

const labelMap = {
  lblRegularPrice: 'label_regular_price',
  lblOrderSummaryCoreDeposit: 'label_cart_orderSummary_coreDeposit',
  lblRefundable: 'label_refundable',
  labelRefundableCoreCharge: 'label_refundable_core_charge',
  lbllineItemWhatIsIt: 'label_cart_lineItem_WhatIsIt',
  lblCoreDetails: 'pagecontent_cart_corePopup_CoreDetails',
  labelRegularPricePlusCore: 'label_regular_price_plus_core',
  lblSeeSavingsInCart: 'label_see_savings_in_cart',
  lblAddToCartDiscount: 'label_ShelfPage_AddToCartDiscount',
};

type PriceQuantityProps = {
  addedToCart: boolean;
  configurableSku: boolean;
  dealAvailable: boolean;
  discountedPrice: string | null;
  retailPrice: string[];
  availProduct?: SkuPricingAndAvailabilityFromAPI;
  staticPrice: number;
  handleQuantityChange: (
    quantity: number,
    quantityErrorMsg: boolean,
    showQuantityWarningMessage?: boolean
  ) => void;
  availabilityCheck: AvailabilityInfoVOFromAPIV2;
  sisterStoreAvailable: boolean;
  skuId: string;
  eligibleForNextDay?: boolean;
  count: number;
  shippingType: ShippingType;
  pageType: string;
  saveNowOrRebateBadges: React.ReactNode | null;
  customizeSKUs: boolean;
  fulfillmentId: number;
  setFulfillmentId: (fulfillmentId: number) => void;
  quantityInputDisabled?: boolean;
};

const PdpPriceQuantity = (props: PriceQuantityProps) => {
  const {
    addedToCart,
    configurableSku,
    dealAvailable,
    discountedPrice,
    retailPrice,
    availProduct,
    count,
    shippingType,
    staticPrice,
    handleQuantityChange,
    availabilityCheck,
    sisterStoreAvailable,
    skuId,
    eligibleForNextDay,
    pageType,
    saveNowOrRebateBadges,
    customizeSKUs,
    fulfillmentId,
    setFulfillmentId,
    quantityInputDisabled,
  } = props;
  const dealDrawerPDPEnabled = useFeatureFlag('DEAL_DRAWER_PDP_ENABLED') === 'true';
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const isDesktopOrTablet = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { isFetchingLocation } = useSelector(({ appData }: ReduxState) => appData);
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const totalCorePrice = (availProduct?.corePrice ?? 0) * count;
  const isMX = locale === countryCodes.mx;
  const isBR = locale === countryCodes.ptBr;

  const [retailPriceDollars, retailPriceCents] = retailPrice;

  let retailPriceInt = parseFloat(`${retailPriceDollars}.${retailPriceCents}`);

  const coreBreakdownEnabled = useFeatureFlag('CORE_BREAKDOWN') === 'true';

  const dealPrice = getDealPrice(
    availProduct?.lowestPrice,
    strikeThroughPricingEnabled,
    discountedPrice,
    configurableSku,
    retailPrice
  );

  const priceLabel = addedToCart ? labels.lblSeeSavingsInCart : labels.lblAddToCartDiscount;

  const counterData = {
    itemQuantity: count,
    orderType: shippingType,
    eligibleForNextDay: eligibleForNextDay,
    productInfo: {
      skuId: skuId,
    },
    unitAmount: staticPrice,
    lineItemPriceInfo: {
      unitPrice: staticPrice,
    },
    clearanceItem: availProduct?.skuAvailabilityInfo?.clearanceItem,
    strikethroughPrice: availProduct?.wasRetailPrice
      ? availProduct.wasRetailPrice
      : discountedPrice,
    fulfillmentId: fulfillmentId,
  };

  const {
    popoverDiscountedPrice,
    popoverUpdatedPrice,
    popoverRetailPrice,
    popoverRetailPriceInt,
    counterPrice,
    popoverDealPrice,
  } = getPopoverPrices({
    wasRetailPrice: availProduct?.wasRetailPrice ?? undefined,
    retailPriceInt,
    discountedPrice,
    count,
    staticPrice,
    dealPrice,
  });
  const totalPrice = (popoverDiscountedPrice + totalCorePrice).toString();

  return (
    <div
      className={cx(styles.priceQuantityWrapper, {
        [styles.quantityMargin]: isDesktopOrTablet || configurableSku || availProduct?.corePrice,
        [styles.makeColumn]: availProduct?.corePrice,
      })}
      data-testid="price-quantity-wrapper"
    >
      <PdpPrice
        dealAvailable={dealAvailable}
        dealDrawerPDPEnabled={dealDrawerPDPEnabled}
        priceLabel={priceLabel}
        data={counterData}
        discountedPrice={`${popoverDiscountedPrice}`}
        updatedDiscountedPrice={(popoverUpdatedPrice as number) ?? 0}
        retailPriceInt={popoverRetailPriceInt}
        corePriceInfoPopover={
          coreBreakdownEnabled ? (
            <CorePriceInfoPopOver
              totalCorePrice={totalCorePrice}
              isBopusMexicoStoreEnabled={isBopusMexicoStoreEnabled}
              totalPrice={totalPrice}
              dealPrice={popoverDealPrice}
            />
          ) : (
            <div className={cx(azCommonStyles['az-caption'])}>
              <div>
                {isFetchingLocation ? (
                  <Skeleton width={70} height={36} animation={'wave'} />
                ) : (
                  <Price priceType="primary" dealPrice={dealPrice} />
                )}
              </div>
              <CorePrice corePrice={totalCorePrice} />
            </div>
          )
        }
        priceInfoPopover={
          <PriceInfoPopover
            discountedPrice={`${popoverDiscountedPrice}`}
            updatedDiscountedPrice={popoverUpdatedPrice ?? 0}
            configurableSku={configurableSku}
            retailPrice={popoverRetailPrice}
            retailPriceInt={popoverRetailPriceInt}
            itemQuantity={count}
          />
        }
        saveNowOrRebateBadges={saveNowOrRebateBadges}
        skuPricingAndAvailability={availProduct}
        count={count}
      />
      {!customizeSKUs && ((isMX && isBopusMexicoStoreEnabled) || (!isMX && !isBR)) && (
        <div
          data-testid={isDesktopOrTablet ? 'quantity-counter-lg' : 'quantity-counter-sm'}
          className={styles.quantityContainer}
        >
          <CartCommonCounterPart
            data={counterData}
            disabled={quantityInputDisabled}
            corePrice={availProduct?.corePrice}
            discountedStrikeThroughPrice={counterPrice}
            availabilityCheck={availabilityCheck}
            sisterStoreAvailable={sisterStoreAvailable}
            handleQuantityChange={handleQuantityChange}
            pageType={pageType}
            fulfillmentId={fulfillmentId}
            setFulfillmentId={setFulfillmentId}
            isNewMessagingShown={locale !== countryCodes.mx}
            isMini
          />
        </div>
      )}
    </div>
  );
};

export default PdpPriceQuantity;
