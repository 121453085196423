/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { IconButton } from '@/components/IconButton/IconButton';
import * as React from 'react';
import styles from './flyout.module.scss';
import CloseIconWhite from '@/public/images/close-icon-white.svg';
import CloseIconBlack from '@/public/images/close.svg';
import { useLabels } from '@/hooks/useLabels';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  handleClose?: () => void;
  focusCloseRef?: boolean;
};

const labelMap = {
  selectAstore: 'label_Select_A_Store',
  closeButton: 'label_Homepage_content_Close',
};

export function FlyoutHeader(props: Props) {
  const labels = useLabels(labelMap);
  const closeRef = React.useRef<HTMLButtonElement>(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  React.useEffect(() => {
    props.focusCloseRef && closeRef.current && closeRef.current.focus();
  }, [props.focusCloseRef]);
  return (
    <div className={styles.container}>
      <h2 data-testid="sister-store-flyout-header" className={styles.header}>
        {labels.selectAstore}
      </h2>
      <IconButton
        data-testid="sister-store-flyout-close-button"
        onClick={props.handleClose}
        className={styles.closeButton}
        ariaLabel={labels.closeButton}
        ref={closeRef}
      >
        {isMobile ? <CloseIconWhite /> : <CloseIconBlack />}
      </IconButton>
    </div>
  );
}
