/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { type CitrusSearchCarouselParams, type CitrusCarouselResponse } from '../interface';
import { getCitrusCatalogId } from '../utils/getCitrusCatalogId';
import { appendVehicleIdFilters } from '../utils/appendVehicleIdFilters';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';
import { getDecodedCookies } from '@/utils/cookie';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import environmentConfig from '@/config/environment';
import { useFeatureFlag } from '@/features/globalConfig';
import { type PageContextType } from '@/next-types';
import { getCitrusSearchCarouselData as internalGetCitrusSearchCarouselData } from '../utils/apiUtils.server';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { usePageType } from '@/features/category/api/getPageType';
import { pageTypes } from '@/constants/page';
import { useRouter } from 'next/router';
import { getCitrusSearchCarouselParams } from '../utils/getCitrusSearchCarouselParams';

const URL = `/api/citrus/search-spa`;
const PROXY_URL = '/v1/ads/generate';

type Options = {
  enabled?: boolean;
} & CitrusSearchCarouselParams;

const getCitrusSearchCarouselDataWithProxy = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusSearchCarouselKey>>) => {
  const [, , , { vehicleId, category }] = queryKey;
  const cookies = getDecodedCookies(['eCookieId', 'rewardsId', 'forceCitrusProd']);
  const rewardsId = cookies.get('rewardsId');
  const eCookieId = cookies.get('eCookieId');
  const forceCitrusProd = !!cookies.get('forceCitrusProd');
  const { baseURL, axiosConfig } = getCitrusProxyConfiguration();

  const response = await getAxiosForClient().post<CitrusCarouselResponse>(
    `${baseURL}${PROXY_URL}`,
    {
      customerId: rewardsId ?? null,
      sessionId: rewardsId || eCookieId,
      placement: 'search-category-product',
      catalogId: getCitrusCatalogId(forceCitrusProd),
      maxNumberOfAds: 3,
      productFilters: appendVehicleIdFilters([`category:${category}`], vehicleId),
      options: {
        filterMode: 'AndOr',
      },
    },
    axiosConfig
  );

  return selector(response.data);
};

const getCitrusSearchCarouselData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusSearchCarouselKey>>) => {
  const [, , , { vehicleId, category }] = queryKey;

  const params = {
    vehicleId,
    category,
  };

  const response = await getAxiosForClient().post<CitrusCarouselResponse>(URL, params);
  return selector(response.data);
};

const selector = (data: CitrusCarouselResponse): CitrusCarouselResponse => data;

const getCitrusSearchCarouselKey = (options: Options) => {
  return ['citrus', 'search', 'carousel', options] as const;
};

export const useCitrusSearchCarouselData = (options: Omit<Options, 'vehicleId' | 'category'>) => {
  const { query } = useRouter();
  const { data: pageTypeData } = usePageType();
  const { data: searchProductData } = useSearchProductData({
    enabled: pageTypeData?.pageType === pageTypes.search && query.partTypesView !== 'true',
  });

  const preferredVehicle = usePreferredVehicle();
  const queryKey: CitrusSearchCarouselParams = getCitrusSearchCarouselParams({
    searchProductData,
    vehicle: preferredVehicle,
  });
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;

  return useQuery({
    enabled: !!citrusEnabled && (options.enabled ?? true),
    queryKey: getCitrusSearchCarouselKey(queryKey),
    queryFn: !CITRUS_PROXY_DISABLED
      ? getCitrusSearchCarouselDataWithProxy
      : getCitrusSearchCarouselData,
    cacheTime: 0,
  });
};

export const getCitrusSearchCarouselDataFromCache = (
  { vehicleId, category }: CitrusSearchCarouselParams,
  queryClient: QueryClient
) => {
  return queryClient.getQueryData<CitrusCarouselResponse>(
    getCitrusSearchCarouselKey({
      vehicleId,
      category,
    })
  );
};

export const prefetchCitrusSearchCarouselData = (
  queryClient: QueryClient,
  context: PageContextType,
  { vehicleId, category }: CitrusSearchCarouselParams,
  eCookieId: string
) => {
  return queryClient.prefetchQuery({
    queryKey: getCitrusSearchCarouselKey({
      vehicleId,
      category,
    }),
    queryFn: () =>
      internalGetCitrusSearchCarouselData({
        req: context.req,
        res: context.res,
        vehicleId,
        eCookieId,
        category,
      }),
  });
};
