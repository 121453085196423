/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTommorow from 'dayjs/plugin/isTomorrow';
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import {
  dayjsFormats,
  dateFormatterForSTH,
  dateFormatterForSTHCheckout,
} from '../../constants/date';
import { useLabel } from '@/hooks/useLabels';
import { useFeatureFlag } from '@/features/globalConfig';
import { useRouter } from 'next/router';
dayjs.extend(isToday);
dayjs.extend(isTommorow);

const EstDeliveryDate = ({
  estimatedDeliveryDate,
  lowerCaseWeekdays = false,
}: {
  estimatedDeliveryDate: string;
  lowerCaseWeekdays?: boolean;
}): any | string => {
  const router = useRouter();
  const isCheckoutV3Applicable = useFeatureFlag('CHECKOUT_V3_ENABLED_US') === 'true';
  const labelToday = useLabel(fulfillmentLabels.label_shipping_Today);
  const labelTomorrow = useLabel(fulfillmentLabels.label_shipping_Tomorrow);
  const isCheckout = router.pathname === '/checkout';

  const formattedArrivesLabel = useMemo(() => {
    const estDeliveryDateTime = dayjs(estimatedDeliveryDate.split(/[T ]/i, 1)[0]);
    if (estDeliveryDateTime.isValid()) {
      if (estDeliveryDateTime.isToday()) {
        return lowerCaseWeekdays ? labelToday.toLocaleLowerCase() : labelToday;
      }

      if (estDeliveryDateTime.isTomorrow()) {
        return lowerCaseWeekdays ? labelTomorrow.toLocaleLowerCase() : labelTomorrow;
      }

      return estDeliveryDateTime.format(
        isCheckoutV3Applicable
          ? isCheckout
            ? dateFormatterForSTHCheckout.weekDayMonthAndDay
            : dateFormatterForSTH.weekDayMonthAndDay
          : dayjsFormats.weekDayMonthAndDay
      );
    }

    return estDeliveryDateTime.toString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedDeliveryDate]);
  return formattedArrivesLabel;
};

export default EstDeliveryDate;
